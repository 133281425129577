import { Popover, Typography } from 'antd';
import moment, { type Moment } from 'moment';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import { TagSkeleton } from '@common/components/TagSkeleton';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import { trackEvent } from '@common/utils/track-helpers';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { useUpdatePrimaryRecordTag } from '../../hooks/useUpdatePrimaryRecordTag';

type ExpirationDatePopoverProps = {
  showLoadingOnUpdate?: boolean;
  tag: {
    _id: string;
    expiresAt?: string;
  };
  primaryRecordId: string;
  children: React.ReactNode;
  onDateAdded?: () => void;
};

export const ExpirationDatePopover = ({
  tag,
  children,
  primaryRecordId,
  onDateAdded,
  showLoadingOnUpdate = false,
}: ExpirationDatePopoverProps) => {
  const { updatePrimaryRecordTag, loading } = useUpdatePrimaryRecordTag();

  const memberRole = useMemberRole();

  const onUpdateTaggingDate = async (date: Moment | null) => {
    if (!tag || (date && date.isSame(tag?.expiresAt))) {
      return;
    }

    await updatePrimaryRecordTag(
      primaryRecordId,
      tag._id,
      date ? date.format() : null,
    );

    onDateAdded?.();

    if (date) {
      trackEvent('User set expiration date on a tag');
    } else {
      trackEvent('User removed expiration date from a tag');
    }
  };

  if (memberRole === ROLES.VIEWER) {
    return children;
  }

  return (
    <Popover
      destroyTooltipOnHide
      placement="bottom"
      trigger={['click']}
      title="Tag expiration date"
      mouseEnterDelay={0.5}
      content={
        <StyledTagStyleWrapper>
          <DatePicker
            data-cy="setExpiresAt"
            style={{ width: '100%' }}
            defaultValue={tag.expiresAt ? moment(tag.expiresAt) : undefined}
            format={defaultDateFormats}
            onChange={onUpdateTaggingDate}
          />
          <Typography.Text style={{ margin: '10px 0 0', fontSize: '12px' }}>
            Set an expiration date if you want to automatically remove this tag
            once the date lapses.
          </Typography.Text>
        </StyledTagStyleWrapper>
      }
    >
      {showLoadingOnUpdate && loading ? (
        <TagSkeleton />
      ) : (
        /* wrap children in an html tag otherwise the Popover doesn't work */
        <StyledPopoverChildren>{children}</StyledPopoverChildren>
      )}
    </Popover>
  );
};

const StyledTagStyleWrapper = styled.div`
  width: 220px;
  padding: 5px 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .ant-input-group-wrapper {
    margin-bottom: 15px;
  }
`;

const StyledPopoverChildren = styled.div`
  cursor: pointer;
`;
