import { useAppSelector } from '@store/hooks';

import type {
  ContextRecord,
  Document,
  FillableForm,
} from '@graphql/types/graphql';
import {
  type DocumentFormToSubmit,
  FillableFormSignDrawer,
} from '@modules/fillable-form/components/FillableFormSignDrawer';
import { getActiveOrganizationId } from '@modules/organization/selectors';
import { useDocumentFormQuery } from './hooks/useDocumentFormQuery';
import { useUpdateDocumentFormMutation } from './hooks/useUpdateDocumentFormMutation';

type FillableFormSignContainerProps = {
  open: boolean;
  onClose: () => void;
  filler: string;
  signer?: { name: string; email: string };
  fillableForm?: FillableForm;
  contextRecord?: Pick<ContextRecord, '_id' | 'name'> | null;
  primaryRecordId: string;
  requestId: string;
  token?: string;
  onSuccess?: () => void;
};

export const FillableFormSignContainer = ({
  open,
  onClose,
  onSuccess,
  fillableForm,
  signer,
  filler,
  contextRecord,
  requestId,
  token,
  primaryRecordId,
}: FillableFormSignContainerProps) => {
  const activeOrganizationId = useAppSelector(getActiveOrganizationId);

  const { documentForm, isDocumentFormLoading } = useDocumentFormQuery({
    fillableFormId: fillableForm?._id,
    partyId: primaryRecordId,
    requestId,
    organization: activeOrganizationId,
    token,
    projectId: contextRecord?._id,
  });

  const { updateDocumentForm } = useUpdateDocumentFormMutation();

  const handleOnUpdateDocumentForm = (documentForm: DocumentFormToSubmit) => {
    return updateDocumentForm({
      ...documentForm,
      token,
      projectId: contextRecord?._id,
      filler,
      requestId,
    });
  };

  return (
    <FillableFormSignDrawer
      open={open}
      onClose={onClose}
      onUpdateDocumentForm={handleOnUpdateDocumentForm}
      filler={filler}
      signer={signer}
      documentForm={documentForm as Document}
      loading={isDocumentFormLoading}
      onSuccess={onSuccess}
      contextRecord={contextRecord}
    />
  );
};
