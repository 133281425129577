import { BulkOperationStatus } from '@graphql/types/graphql';
import { CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/context-records/constants';
import { PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/primary-records/constants';
import { REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/request-records/constants';
import { message } from 'antd';
import { useOperationsUpdates } from '../hooks/useOperationsUpdates';

const OPERATIONS_COMPLETED_MESSAGES: Record<string, string> = {
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.deleteRequestRecords]:
    'Your Request(s) have been successfully deleted',
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.createRequestRecords]:
    'Your Request(s) have been successfully created',
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.archiveRequestRecords]:
    'Your Request(s) have been successfully archived',
  [REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES.activateRequestRecords]:
    'Your Request(s) have been successfully made active',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.setPrimaryRecordsDefaultEmailRecipients]:
    'Your contact(s) have been successfully set as default email recipients',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.createPrimaryRecords]:
    'Your Record(s) have been successfully created',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.deletePrimaryRecords]:
    'Your Record(s) have been successfully deleted',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.archivePrimaryRecords]:
    'Your Record(s) have been successfully archived',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.makeActivePrimaryRecords]:
    'Your Record(s) have been successfully made active',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.removeTagsFromPrimaryRecords]:
    'Tag(s) have been successfully removed from your Record(s)',
  [CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES.createContextRecords]:
    'Your Record(s) have been successfully created',
  [CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES.deleteContextRecords]:
    'Your Record(s) have been successfully deleted',
  [CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES.archiveContextRecords]:
    'Your Record(s) have been successfully archived',
  [CONTEXT_RECORDS_ASYNC_OPERATIONS_NAMES.makeActiveContextRecords]:
    'Your Record(s) have been successfully made active',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.sendPrimaryRecordsRequest]:
    'Emails have been successfully sent',
  [PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES.addTagsToPrimaryRecords]:
    'Your tag(s) have been successfully assigned',
};

const OperationsUpdatesNotifier = () => {
  useOperationsUpdates({
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: (data) => {
      if (!data.operations?.operation?.name) {
        return;
      }

      message.success(
        OPERATIONS_COMPLETED_MESSAGES[data.operations?.operation?.name],
      );
    },
  });

  return null;
};

export default OperationsUpdatesNotifier;
