import useSetState from '@common/hooks/useSetState';

type PublicNoteModalState = {
  visible: boolean;
  itemData?: {
    code: string;
    publicNotes?: string;
  };
  isEdit?: boolean;
};

export const usePublicNoteModalState = () => {
  const [publicNoteModalState, setPublicNoteModalState] =
    useSetState<PublicNoteModalState>({
      visible: false,
    });

  return {
    publicNoteModalState,
    setPublicNoteModalState,
  };
};
