import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const TAGS_BATCH_SIZE = 500;

const PRIMARY_RECORD_QUERY = graphql(`
  query PrimaryRecordTagsList($input: PrimaryRecordInput!, $tagsInput: PrimaryRecordTagsInput!) {
    primaryRecord(input: $input) {
      _id
      name
      tags(input: $tagsInput) {
        nodes {
          _id
          name
          expiresAt
          createdAt
        }
      }
    }
  }
`);

export function usePrimaryRecordSubheaderQuery({
  primaryRecordId,
  skip,
}: { primaryRecordId: string; skip: boolean }) {
  const { data, loading, refetch, networkStatus } = useQuery(
    PRIMARY_RECORD_QUERY,
    {
      variables: {
        input: {
          id: primaryRecordId,
        },
        tagsInput: {
          first: TAGS_BATCH_SIZE,
        },
      },
      skip,
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    tags: data?.primaryRecord?.tags?.nodes || [],
    loading,
    refetch,
    isRefetching: networkStatus === NetworkStatus.refetch,
  };
}
