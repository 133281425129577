import { FILTER_OPERATORS } from '@common/constants/filters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { RemoveTagsList } from '@modules/primary-records/components/RemoveTagsList';
import { useRemoveTagsFromPrimaryRecords } from '@modules/primary-records/hooks/useRemoveTagsFromPrimaryRecords';
import { Modal, Skeleton, message } from 'antd';
import { useState } from 'react';
import { usePrimaryRecordsTagsQuery } from '../../hooks/usePrimaryRecordsTagsQuery';
import { usePrimaryRecordQuery } from './hooks/usePrimaryRecordQuery';

type RemoveTagsFromPrimaryRecordModalProps = {
  open: boolean;
  primaryRecordId: string;
  primaryObjectName: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const RemoveTagsFromPrimaryRecordModal = ({
  open,
  primaryRecordId,
  primaryObjectName,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: RemoveTagsFromPrimaryRecordModalProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { primaryRecord, loading: primaryRecordDataLoading } =
    usePrimaryRecordQuery({
      id: primaryRecordId,
      skip: !open || !primaryRecordId,
    });

  const { primaryRecordsTags, loading: tagsLoading } =
    usePrimaryRecordsTagsQuery({
      filters: {
        and: [
          {
            name: 'id',
            operator: FILTER_OPERATORS.equal,
            value: primaryRecordId,
          },
        ],
      },
      skip: !open || !primaryRecordId,
    });

  const { removeTagsFromPrimaryRecords, loading: isRemovingTags } =
    useRemoveTagsFromPrimaryRecords();

  const handleArchive = async () => {
    const res = await removeTagsFromPrimaryRecords({
      tagIds: selectedTags,
      filters: {
        and: [
          {
            name: 'id',
            operator: FILTER_OPERATORS.equal,
            value: primaryRecordId,
          },
        ],
      },
    });

    const status = res.data?.removeTagsFromPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Tags have been removed from ${primaryRecord?.name} successfully.`,
      );
      setSelectedTags([]);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Tags removal for ${primaryRecord?.name} has been scheduled.`,
      );
      setSelectedTags([]);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The tags removal for ${primaryRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleSelect = (tagId: string) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags((prev) => prev.filter((tag) => tag !== tagId));
    } else {
      setSelectedTags((prev) => [...prev, tagId]);
    }
  };

  return (
    <Modal
      open={open}
      okButtonProps={{
        loading: isRemovingTags,
        disabled: selectedTags.length === 0,
      }}
      okText="Remove"
      title="Remove tags"
      onCancel={() => {
        setSelectedTags([]);
        onCancel?.();
      }}
      onOk={handleArchive}
    >
      {tagsLoading || primaryRecordDataLoading ? (
        <Skeleton active />
      ) : (
        <RemoveTagsList
          tags={primaryRecordsTags}
          onSelect={handleSelect}
          selectedTags={selectedTags}
          recordName={primaryRecord?.name}
          objectLabel={primaryObjectName}
        />
      )}
    </Modal>
  );
};
