import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import { HideForViewerRole } from '@modules/organization-member';
import { CircleProgress } from '../CircleProgress';

export const ComplianceSummaryContent = ({
  profileName,
  isCustomComplianceProfile,
  hideEditAction,
  compliantSubjectsCount,
  totalSubjectsCount,
  onEditClick,
}: {
  profileName: string;
  isCustomComplianceProfile?: boolean;
  hideEditAction?: boolean;
  compliantSubjectsCount?: number;
  totalSubjectsCount?: number;
  onEditClick?: () => void;
}) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const nameLabel = isComplianceRequirementsAddOnEnabled
    ? 'PROFILE'
    : 'CHECKLIST';

  return (
    <StyledContent data-cy="complianceSummaryContent">
      <StyledComplianceProfileWrapper>
        <StyledNameWrapper>
          <span className="prefix">{nameLabel}:</span>
          {isCustomComplianceProfile ? (
            <>
              <BaseComplianceProfileIcon />{' '}
            </>
          ) : null}
          <h3 className="title">{profileName}</h3>
        </StyledNameWrapper>
        {hideEditAction ? null : (
          <HideForViewerRole>
            <StyledEditButton
              data-cy="edit-button"
              shape="circle"
              type="link"
              onClick={() => {
                onEditClick?.();
              }}
            >
              <EditOutlined />
            </StyledEditButton>
          </HideForViewerRole>
        )}
      </StyledComplianceProfileWrapper>

      {isComplianceRequirementsAddOnEnabled ? (
        <CircleProgress
          currentActiveItemsCount={compliantSubjectsCount ?? 0}
          totalItemsCount={totalSubjectsCount ?? 0}
        />
      ) : null}
    </StyledContent>
  );
};

const StyledContent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const StyledComplianceProfileWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(195, 209, 226, 0.8);
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.blueGray};
`;

const StyledNameWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .prefix {
    color: ${({ theme }) => theme.colors.lightTextGray};
  }

  .title {
    margin: 0;
  }
`;

const StyledEditButton = styled(Button)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.paleGray};
  padding: 0 5px;
`;
