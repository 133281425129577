import { Popover } from 'antd';
import moment, { type Moment } from 'moment';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import { trackEvent } from '@common/utils/track-helpers';
import { useMutationWithRedux } from '@graphql/hooks';
import { getOperationName } from '@graphql/utils';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { UPDATE_TAGGING_MUTATION } from '@modules/party-tag/mutations';
import { UPDATE_TAGGING } from '@modules/tagging/actions';

type ExpirationDatePopoverProps = {
  tagging: {
    _id: string;
    expiresAt?: string;
  };
  children: React.ReactNode;
};

const ExpirationDatePopover = ({
  tagging,
  children,
}: ExpirationDatePopoverProps) => {
  const [updatePartyTagMutation] = useMutationWithRedux(
    UPDATE_TAGGING_MUTATION,
    {
      reduxActionKey: getOperationName(UPDATE_TAGGING_MUTATION),
      reduxActionType: UPDATE_TAGGING,
    },
  );

  const memberRole = useMemberRole();

  const onUpdateTaggingDate = (date: Moment | null) => {
    if (!tagging || (date && date.isSame(tagging?.expiresAt))) {
      return;
    }

    updatePartyTagMutation({
      variables: {
        data: {
          _id: tagging._id,
          expiresAt: date ? date.format() : null,
        },
      },
    });

    if (date) {
      trackEvent('User set expiration date on a tag');
    } else {
      trackEvent('User removed expiration date from a tag');
    }
  };

  if (memberRole === ROLES.VIEWER) {
    return children;
  }

  return (
    <Popover
      destroyTooltipOnHide
      placement="bottom"
      trigger={['click']}
      title="Tag expiration date"
      mouseEnterDelay={0.5}
      content={
        <StyledTagStyleWrapper>
          <DatePicker
            data-cy="setExpiresAt"
            style={{ width: '100%' }}
            defaultValue={
              tagging.expiresAt ? moment(tagging.expiresAt) : undefined
            }
            format={defaultDateFormats}
            onChange={onUpdateTaggingDate}
          />
          <p style={{ margin: '10px 0 0', fontSize: '12px' }}>
            Set an expiration date if you want to automatically remove this tag
            once the date lapses.
          </p>
        </StyledTagStyleWrapper>
      }
    >
      {/* wrap children in empty html tag otherwise the Popover doesn't work */}
      <>{children}</>
    </Popover>
  );
};

const StyledTagStyleWrapper = styled.div`
  width: 220px;
  padding: 5px 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .ant-input-group-wrapper {
    margin-bottom: 15px;
  }
`;

export default ExpirationDatePopover;
