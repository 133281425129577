import { AutoComplete, Tag } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import { useQuery } from '@graphql/hooks';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { AUTOCOMPLETE_PARTY_TAGS } from './queries';
import { getExistingPartyTagByName, getPartyTagsWithNewTag } from './utils';

export type Tag = {
  _id?: string;
  name: string;
  color?: string | null;
};

type AutoCompletePartyTagProps = {
  disabled?: boolean;
  onNewTagSelected: (newTag: Tag) => void;
  onTagSelected: (tag: Tag) => void;
};

const AutoCompletePartyTag = ({
  onTagSelected,
  onNewTagSelected,
  disabled = false,
}: AutoCompletePartyTagProps) => {
  const [newTagValue, setNewTagValue] = useState<Tag | undefined>();
  const [isVisibleInputTag, setIsVisibleInputTag] = useState(false);

  const { loading, data } = useQuery(AUTOCOMPLETE_PARTY_TAGS);
  const partyTags = data?.listPartyTags || [];

  const onTagSelect = async (tag?: Tag) => {
    if (!tag) {
      return;
    }

    setNewTagValue(undefined);
    const trimmedTagName = tag?.name?.trim();

    if (!trimmedTagName) return;

    const existingPartyTag = getExistingPartyTagByName(
      trimmedTagName,
      partyTags,
    );

    existingPartyTag ? onTagSelected(existingPartyTag) : onNewTagSelected(tag);
  };

  const options = getPartyTagsWithNewTag(partyTags, newTagValue);

  return (
    <HideForViewerRole>
      {isVisibleInputTag ? (
        loading ? (
          <Spinner />
        ) : (
          <StyledAutoComplete<Tag | undefined>
            data-cy="newTagInput"
            autoFocus
            defaultActiveFirstOption
            size="small"
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            filterOption={(text, option) =>
              option?.key.toLowerCase().indexOf(text.toLowerCase()) !== -1
            }
            onSelect={onTagSelect}
            // @ts-ignore casting string since this is what receives onChange
            onChange={(tagName: string) => {
              if (!tagName) {
                setNewTagValue(undefined);
                return;
              }

              const existingPartyTag = getExistingPartyTagByName(
                tagName,
                partyTags,
              );

              setNewTagValue(existingPartyTag || { name: tagName });
            }}
            onBlur={() => {
              onTagSelect(newTagValue);
              setIsVisibleInputTag(false);
            }}
          >
            {options.map((tag) => (
              <AutoComplete.Option key={tag.name} value={tag}>
                {tag.name}
              </AutoComplete.Option>
            ))}
          </StyledAutoComplete>
        )
      ) : (
        <NewTag
          data-cy="newTagButton"
          onClick={() => setIsVisibleInputTag(true)}
        >
          + Add tag...
        </NewTag>
      )}
    </HideForViewerRole>
  );
};

const StyledAutoComplete = styled(AutoComplete)`
  && {
    box-shadow: none;
  }

  height: 24px;
  width: 82px;
`;

const NewTag = styled(Tag)`
  height: 24px;
  line-height: 22px;
  border-style: dashed;
  color: rgba(80, 119, 167, 0.65);
  background-color: #fff;
  cursor: pointer;
  border-color: #c5d6ec;

  &:hover {
    color: rgba(80, 119, 167, 1);
    border-color: rgba(80, 119, 167, 1);
  }
`;

export default AutoCompletePartyTag;
