import type { GetRowData } from '@common/components/Table/types';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';
import { SERVER_FILTERS_CONFIG } from '../../ContextRecordsTable/constants';

export const RECORD_COMPLIANCE_PROFILES_QUERY = graphql(`
  query ContextRecordComplianceProfilesTable(
    $contextRecordInput: ContextRecordInput!
    $complianceProfilesInput: ComplianceProfilesInput!
  ) {
    contextRecord(input: $contextRecordInput) {
      _id
      complianceProfiles(input: $complianceProfilesInput) {
        nodes {
          _id
          name
        }
        totalCount
      }
    }
  }
`);

export const useContextRecordComplianceProfile = ({
  contextRecordId,
}: {
  contextRecordId: string;
}) => {
  const [fetchProfiles, { refetch }] = useLazyQuery(
    RECORD_COMPLIANCE_PROFILES_QUERY,
  );

  const fetchComplianceProfiles: GetRowData = useCallback(
    async ({ filters, endRow, startRow }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const { data } = await fetchProfiles({
        variables: {
          contextRecordInput: {
            id: contextRecordId,
          },
          complianceProfilesInput: {
            sort: {
              name: 1,
            },
            filter: activeFilters,
            offset: startRow,
            first: endRow - startRow,
          },
        },
      });

      return {
        rowData: data?.contextRecord?.complianceProfiles?.nodes || [],
        rowCount: data?.contextRecord?.complianceProfiles?.totalCount || 0,
      };
    },
    [contextRecordId, fetchProfiles],
  );

  return {
    fetchComplianceProfiles,
    refetchComplianceProfiles: refetch,
  };
};
