import AutoCompletePartyTag from '@modules/party-tag/containers/AutoCompletePartyTag';
import { Tag } from '@modules/party/components/Tag/Tag';
import { styled } from 'styled-components';

type Tag = {
  _id?: string;
  name: string;
};

type AddTagsFormProps = {
  tags: Tag[];
  hasExpirationPopover?: boolean;
  onSelect: (tag: Tag) => void;
  onRemove: (tag: Tag) => void;
};

export const AddTagsForm = ({ tags, onSelect, onRemove }: AddTagsFormProps) => {
  return (
    <StyledTags>
      {tags.map((tag, index) => (
        <Tag
          key={`key_${index}`}
          label={tag.name}
          onClose={() => {
            onRemove(tag);
          }}
        />
      ))}
      <AutoCompletePartyTag
        onTagSelected={onSelect}
        onNewTagSelected={onSelect}
      />
    </StyledTags>
  );
};

const StyledTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
