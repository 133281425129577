import type { PrimaryRecordTag } from '@graphql/types/graphql';
import { Tag, Typography } from 'antd';
import styled from 'styled-components';

const { CheckableTag } = Tag;

type RemoveTagsListProps = {
  tags: Pick<PrimaryRecordTag, '_id' | 'name'>[];
  onSelect: (tagId: string) => void;
  selectedTags: string[];
  recordName?: string;
  objectLabel: string;
};

export const RemoveTagsList = ({
  tags,
  onSelect,
  selectedTags,
  recordName,
  objectLabel,
}: RemoveTagsListProps) => {
  return tags?.length > 0 ? (
    <div>
      <Typography.Paragraph>
        Select which tags you want to remove from {recordName || objectLabel}
      </Typography.Paragraph>
      <StyledTags>
        {tags.map(({ _id, name }) => (
          <CheckableTag
            key={`key_${_id}`}
            checked={selectedTags.includes(_id)}
            onChange={() => onSelect(_id)}
          >
            {name}
          </CheckableTag>
        ))}
      </StyledTags>
    </div>
  ) : (
    <Typography.Paragraph>
      No tags available on selected {objectLabel?.toLowerCase()}
    </Typography.Paragraph>
  );
};

const StyledTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  .ant-tag-checkable {
    cursor: pointer;
  }

  .ant-tag {
    margin-inline-end: 0;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: red;
  }

  .ant-tag-checkable-checked {
    background-color: red;
  }
`;
