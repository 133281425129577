import { Skeleton } from 'antd';
import styled from 'styled-components';

export const TagSkeleton = () => {
  return <StyledSkeleton active size="small" />;
};

const StyledSkeleton = styled(Skeleton.Input)`
  &&.ant-skeleton-input {
    border-radius: 99em;
    width: 65px;
    min-width: 65px;
  }
`;
