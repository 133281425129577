import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { RemoveTagsList } from '@modules/primary-records/components/RemoveTagsList';
import { useRemoveTagsFromPrimaryRecords } from '@modules/primary-records/hooks/useRemoveTagsFromPrimaryRecords';
import { Modal, Skeleton, message } from 'antd';
import { useState } from 'react';
import { usePrimaryRecordsTagsQuery } from '../../hooks/usePrimaryRecordsTagsQuery';

type RemoveTagsFromPrimaryRecordsModalProps = {
  open: boolean;
  count: number;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  filters?: ServerFilterInput;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const RemoveTagsFromPrimaryRecordsModal = ({
  open,
  count,
  filters,
  primaryObject,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: RemoveTagsFromPrimaryRecordsModalProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { primaryRecordsTags, loading: tagsLoading } =
    usePrimaryRecordsTagsQuery({
      filters,
      skip: !open,
    });

  const { removeTagsFromPrimaryRecords, loading: isRemovingTags } =
    useRemoveTagsFromPrimaryRecords();

  const objectLabel = count > 1 ? primaryObject.pluralName : primaryObject.name;

  const handleArchive = async () => {
    const res = await removeTagsFromPrimaryRecords({
      tagIds: selectedTags,
      filters,
    });

    const status = res.data?.removeTagsFromPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Tags have been removed from ${count} ${objectLabel} successfully.`,
      );
      setSelectedTags([]);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Tags removal for ${count} ${objectLabel} has been scheduled.`,
      );
      setSelectedTags([]);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The tags removal for ${count} ${objectLabel} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const handleSelect = (tagId: string) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags((prev) => prev.filter((tag) => tag !== tagId));
    } else {
      setSelectedTags((prev) => [...prev, tagId]);
    }
  };

  return (
    <Modal
      open={open}
      okButtonProps={{
        loading: isRemovingTags,
        disabled: selectedTags.length === 0,
      }}
      okText="Remove"
      title="Remove tags"
      onCancel={() => {
        setSelectedTags([]);
        onCancel?.();
      }}
      onOk={handleArchive}
    >
      {tagsLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 3 }} />
      ) : (
        <RemoveTagsList
          tags={primaryRecordsTags}
          onSelect={handleSelect}
          selectedTags={selectedTags}
          objectLabel={objectLabel}
        />
      )}
    </Modal>
  );
};
