import {
  CloseOutlined,
  FolderOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Alert, Button, Form, Input, Skeleton, message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import { Modal } from '@common/components/Modal';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ComplianceProfilesSelect } from '@modules/compliance-profile';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { RichSelect } from '../../components/RichSelect';
import { useCreateRequestRecordsMutation } from '../../hooks/useCreateRequestRecordsMutation';
import {
  DEFAULT_CONTEXT,
  DEFAULT_CONTEXT_LENGTH,
  MAX_NEW_REQUESTS_COUNT,
} from './constants';
import { useContextRecords } from './hooks/useContextRecords';
import { usePrimaryRecordType } from './hooks/usePrimaryRecordType';

type CreatePrimaryRecordRequestModalProps = {
  open: boolean;
  primaryRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const ALERT_DESCRIPTION =
  'Give your request a unique name. Your request name will be displayed to contacts on the Portal page.';

type FormValues = {
  requests: {
    name: string;
    contextRecordId: string;
    complianceProfileId: string;
  }[];
};

type RecordTypeConfig = {
  relatedLabel: string;
  placeholder: string;
  defaultRelated?: { _id: string; name: string };
  hideRelated: (length: number) => boolean;
  getRelatedIcon?: (option: { _id: string; name: string }) => React.ReactNode;
};

const recordTypeConfig: RecordTypeConfig = {
  relatedLabel: 'Project',
  placeholder: 'Select a project',
  defaultRelated: DEFAULT_CONTEXT,
  hideRelated: (length) => length <= DEFAULT_CONTEXT_LENGTH,
  getRelatedIcon: (option) =>
    option._id === DEFAULT_CONTEXT._id ? (
      <ShopOutlined className="item-icon" />
    ) : (
      <FolderOutlined className="item-icon" />
    ),
};

const CreatePrimaryRecordRequestModalInner = ({
  open,
  primaryRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: CreatePrimaryRecordRequestModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const [form] = Form.useForm<FormValues>();

  const { createRequestRecords, loading: createRequestRecordsLoading } =
    useCreateRequestRecordsMutation();

  const { primaryRecordType } = usePrimaryRecordType({
    primaryRecordId: primaryRecordId,
  });

  const {
    records,
    recordsTotalCount,
    loading: recordsLoading,
    fetchMoreRecords,
    filterRecordsByName,
    totalAvailableRecords,
  } = useContextRecords();

  const handleOnFormSubmit = async () => {
    try {
      await form.validateFields();
      const { requests } = form.getFieldsValue();

      const res = await createRequestRecords({
        requestRecords: requests.map((request) => ({
          name: request.name,
          ...(request.contextRecordId !== DEFAULT_CONTEXT._id && {
            contextRecordId: request.contextRecordId,
          }),
          primaryRecordId: primaryRecordId,
          complianceProfileId: request.complianceProfileId,
        })),
        recordType: 'primaryRecordId',
        recordId: primaryRecordId,
      });

      const status = res.data?.createRequestRecords.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(
          `${requests.length} ${pluralize('request', requests.length)} created successfully.`,
        );
        onCompleted?.();
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(
          `${requests.length} ${pluralize('request', requests.length)} have been scheduled for creation.`,
        );
        onScheduled?.();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The creation of ${requests.length} ${pluralize('request', requests.length)} has failed. Please try again or contact support.`,
        );
        onFailed?.();
      }
    } catch (error) {
      message.error('Error: form validation failed.');
      return;
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);

  //? Set initial values when the primary record type is loaded
  useEffect(() => {
    form.setFieldsValue({
      requests: [
        {
          name: 'Request 1',
          contextRecordId: DEFAULT_CONTEXT._id,
          complianceProfileId:
            primaryRecordType?.defaultComplianceProfile?._id ?? null,
        },
      ],
    });
  }, [primaryRecordType?.defaultComplianceProfile?._id, form]);

  return (
    <>
      <GlobalStyle />
      <StyledModal
        open={open}
        title="New requests"
        onCancel={() => {
          onCancel?.();
        }}
        okText="Create requests"
        onOk={handleOnFormSubmit}
        okButtonProps={{
          loading: createRequestRecordsLoading,
        }}
      >
        <StyledCreateForm>
          <StyledAlert closable type="info" description={ALERT_DESCRIPTION} />

          <div className="row header">
            <span>Request name</span>
            {!recordTypeConfig.hideRelated(totalAvailableRecords) && (
              <span>{recordTypeConfig.relatedLabel}</span>
            )}
            <span>
              {isComplianceRequirementsAddOnEnabled ? 'Profile' : 'Checklist'}
            </span>
          </div>

          <Form name="dynamicRequestsForm" autoComplete="off" form={form}>
            <Form.List name="requests">
              {(fields, { add, remove }) => (
                <>
                  <StyledList>
                    {fields.length === 0 && recordsLoading && (
                      <Skeleton.Input
                        block
                        active
                        style={{ marginBottom: 12 }}
                      />
                    )}
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="row">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input a valid request name.',
                            },
                          ]}
                        >
                          <Input placeholder="Request name" />
                        </Form.Item>
                        {!recordTypeConfig.hideRelated(
                          totalAvailableRecords,
                        ) && (
                          <Form.Item
                            {...restField}
                            name={[name, 'contextRecordId']}
                            rules={[
                              {
                                required: true,
                                message: 'This input is required',
                              },
                            ]}
                          >
                            <RichSelect
                              optionsList={records}
                              optionsTotalCount={recordsTotalCount}
                              loading={recordsLoading}
                              placeholder={recordTypeConfig.placeholder}
                              onSearch={filterRecordsByName}
                              onLoadMore={fetchMoreRecords}
                            >
                              {(option) => (
                                <>
                                  {recordTypeConfig.getRelatedIcon?.(option)}
                                  <span>{option.name}</span>
                                </>
                              )}
                            </RichSelect>
                          </Form.Item>
                        )}
                        <Form.Item
                          {...restField}
                          name={[name, 'complianceProfileId']}
                          getValueFromEvent={(profile) => profile._id}
                        >
                          <ComplianceProfilesSelect
                            size={'middle'}
                            placeholder={
                              isComplianceRequirementsAddOnEnabled
                                ? 'Select a Compliance Profile'
                                : 'Select a Checklist'
                            }
                            defaultValue={
                              primaryRecordType?.defaultComplianceProfile?._id
                            }
                          />
                        </Form.Item>

                        <CloseOutlined
                          className="delete"
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                  </StyledList>
                  <Form.Item>
                    <Button
                      type="dashed"
                      disabled={fields.length >= MAX_NEW_REQUESTS_COUNT}
                      onClick={() =>
                        add({
                          name: `Request ${fields.length + 1}`,
                          contextRecordId: DEFAULT_CONTEXT._id,
                          complianceProfileId:
                            primaryRecordType?.defaultComplianceProfile?._id,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add request
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </StyledCreateForm>
      </StyledModal>
    </>
  );
};

export const CreatePrimaryRecordRequestModal = withControlledMount(
  CreatePrimaryRecordRequestModalInner,
);

const StyledModal = styled(Modal)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledCreateForm = styled.section`
  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) 24px;
    align-items: baseline;
    gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  }

  .delete {
    grid-column: span 1 / -1;
  }

  .header {
    font-weight: bold;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  padding: 9px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const StyledList = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const GlobalStyle = createGlobalStyle`
  .item-icon {
    margin-right: 5px;
  }
`;
