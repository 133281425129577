import { gql } from '@apollo/client';
import { useMutation } from '@graphql/hooks';

const UPDATE_PRIMARY_RECORD_TAG_MUTATION = gql`
  mutation UpdatePrimaryRecordTag($input: UpdatePrimaryRecordTagInput!) {
    updatePrimaryRecordTag(input: $input) {
      _id
    }
  }
`;

export const useUpdatePrimaryRecordTag = () => {
  const [updatePrimaryRecordTagMutation, { loading }] = useMutation(
    UPDATE_PRIMARY_RECORD_TAG_MUTATION,
  );

  const updatePrimaryRecordTag = (
    primaryRecordId: string,
    tagId: string,
    expiresAt: string | null,
  ) =>
    updatePrimaryRecordTagMutation({
      variables: { input: { primaryRecordId, tag: { id: tagId, expiresAt } } },
    });

  return {
    updatePrimaryRecordTag,
    loading,
  };
};
