import type { ComplianceRequirement } from '@graphql/types/graphql';
import { HideForViewerRole } from '@modules/organization-member';
import {
  RequirementComplianceStatus,
  type SubjectComplianceStatus,
} from '@trustlayer/common';
import { ThreeDotsMenu } from '@trustlayer/ui';
import type { ACTION_TYPES } from '../../constants';
import { getRequirementActions } from './RequirementActionsMenu.utils';

export const RequirementActionsMenu = ({
  subjectStatus,
  requirement,
  onClick,
}: {
  subjectStatus: SubjectComplianceStatus;
  requirement: ComplianceRequirement;
  onClick: (actionKey: ACTION_TYPES) => void;
}) => {
  return (
    <HideForViewerRole>
      <ThreeDotsMenu<ACTION_TYPES>
        items={getRequirementActions({
          status: requirement.status,
          hasNote: Boolean(requirement.publicNotes),
          inherited:
            subjectStatus === RequirementComplianceStatus.Overridden ||
            subjectStatus === RequirementComplianceStatus.Waived,
        })}
        onClick={onClick}
      />
    </HideForViewerRole>
  );
};
