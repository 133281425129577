import Flex from '@common/components/Flex';
import type { ComplianceModule } from '@graphql/types/graphql';
import ComplianceProfileDrawer from '@modules/compliance-profile/containers/ComplianceProfileDrawer';
import { useComplianceProfileEditor } from '@modules/feature-flags/hooks';
import {
  ComplianceSummary,
  ActionKeys as ComplianceSummaryActionKeys,
} from '@modules/request-records';
import { AssignComplianceProfileModal } from '@modules/request-records/containers/AssignComplianceProfileModal';
import { RequestRecordComplianceProfile } from '@modules/request-records/containers/RequestRecordComplianceProfile/RequestRecordComplianceProfile';
import { UnassignComplianceProfileModal } from '@modules/request-records/containers/UnassignComplianceProfileModal/UnassignComplianceProfileModal';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { RequestRecordStatus } from '@trustlayer/common';
import { FullWidthSpace } from '@trustlayer/ui';
import { Card, Result } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useRequestRecordComplianceQuery } from './hooks/useRequestRecordComplianceQuery';

export function RequestRecordCompliance() {
  const { isComplianceProfileEditorFeatureFlagEnabled } =
    useComplianceProfileEditor();

  const [isComplianceEditorVisible, setIsComplianceEditorVisible] =
    useState(false);

  const [
    isAssignComplianceProfileModalVisible,
    setIsAssignComplianceProfileModalVisible,
  ] = useState(false);

  const [
    isUnassignComplianceProfileModalVisible,
    setIsUnassignComplianceProfileModalVisible,
  ] = useState(false);

  const { requestId, recordId } = useParams<RequestRecordsRoutesParams>();

  const {
    requestRecord,
    loadingRequestRecordCompliance,
    errorRequestRecordCompliance,
    refetchRequestRecordCompliance,
  } = useRequestRecordComplianceQuery({ requestRecordId: requestId! });

  const complianceProfile = requestRecord?.complianceProfile;
  const complianceStats = requestRecord?.complianceStats;

  const isRequestRecordArchived =
    requestRecord?.status === RequestRecordStatus.Archived;

  const complianceModules = (requestRecord?.complianceModules ?? []).filter(
    (m) => m,
  ) as ComplianceModule[];

  if (errorRequestRecordCompliance) {
    return (
      <StyledErrorWrapper direction="vertical" justify="center">
        <Result
          status="500"
          title="500"
          subTitle="Oops! Something went wrong. Please try again later."
        />
      </StyledErrorWrapper>
    );
  }

  const handleComplianceSummaryActionClick = (
    actionKey: ComplianceSummaryActionKeys,
  ) => {
    if (actionKey === ComplianceSummaryActionKeys.edit) {
      if (isComplianceProfileEditorFeatureFlagEnabled) {
        return setIsComplianceEditorVisible(true);
      }
      return setIsAssignComplianceProfileModalVisible(true);
    }

    if (actionKey === ComplianceSummaryActionKeys.assign) {
      setIsAssignComplianceProfileModalVisible(true);
    }

    if (actionKey === ComplianceSummaryActionKeys.unassign) {
      setIsUnassignComplianceProfileModalVisible(true);
    }
  };

  return (
    <>
      <ComplianceProfileDrawer
        complianceProfileId={complianceProfile?._id}
        requestRecordId={requestId}
        partyName={requestRecord?.name}
        open={isComplianceEditorVisible}
        onClose={() => setIsComplianceEditorVisible(false)}
        onSaved={() => {
          refetchRequestRecordCompliance();
        }}
      />

      <AssignComplianceProfileModal
        isShow={isAssignComplianceProfileModalVisible}
        requestRecordId={requestId!}
        complianceProfile={complianceProfile!}
        onSuccess={() => {
          setIsAssignComplianceProfileModalVisible(false);
          refetchRequestRecordCompliance();
        }}
        onClose={() => setIsAssignComplianceProfileModalVisible(false)}
      />

      <UnassignComplianceProfileModal
        open={isUnassignComplianceProfileModalVisible}
        requestRecordId={requestId!}
        onCancel={() => setIsUnassignComplianceProfileModalVisible(false)}
        onCompleted={() => {
          setIsUnassignComplianceProfileModalVisible(false);
          refetchRequestRecordCompliance();
        }}
      />

      <StyledWrapper size="small" direction="vertical">
        <ComplianceSummary
          hideActions={isRequestRecordArchived}
          hasProfile={Boolean(complianceProfile)}
          isCustomComplianceProfile={Boolean(
            complianceProfile?.baseComplianceProfile,
          )}
          name={complianceProfile?.name}
          onActionClick={handleComplianceSummaryActionClick}
          loading={loadingRequestRecordCompliance}
          compliantSubjectsCount={complianceStats?.compliantSubjects}
          totalSubjectsCount={complianceStats?.totalSubjects}
        />

        {loadingRequestRecordCompliance ? (
          <>
            <Card loading />
            <Card loading />
            <Card loading />
          </>
        ) : (
          <RequestRecordComplianceProfile
            requestRecord={requestRecord!}
            primaryRecord={{ _id: recordId! }}
            complianceModules={complianceModules}
            documentsChecklist={requestRecord?.documentsChecklist || []}
            onComplianceChanged={() => {
              refetchRequestRecordCompliance();
            }}
          />
        )}
      </StyledWrapper>
    </>
  );
}

const StyledWrapper = styled(FullWidthSpace)`
  padding: 16px;
`;

const StyledErrorWrapper = styled(Flex)`
  height: 100%;
`;
