import { useQuery } from '@graphql/hooks';

import { graphql } from '@graphql/types';

const VISIBLE_ATTRIBUTES_COUNT = 8;

export const ATTRIBUTES_QUERY = graphql(`
  query SidebarAttributesPrimaryRecord($input: PrimaryRecordInput!, $attributesInput: RecordAttributesInput) {
    primaryRecord(input: $input) {
      _id
      attributes(input: $attributesInput) {
        totalCount
        nodes {
          ... on SystemAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
              key
            }
          }
          ... on CustomAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
              key
            }
          }
        }
      }
    }
  }
`);

export const useAttributesSidebar = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const { data, loading, error, refetch } = useQuery(ATTRIBUTES_QUERY, {
    variables: {
      input: {
        id: primaryRecordId,
      },
      attributesInput: {
        first: VISIBLE_ATTRIBUTES_COUNT,
        offset: 0,
      },
    },
    skip: !primaryRecordId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    attributes: data?.primaryRecord.attributes?.nodes || [],
    attributesTotalCount: data?.primaryRecord.attributes?.totalCount || 0,
    loading,
    error,
    refetchAttributes: refetch,
  };
};
