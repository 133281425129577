import { DeleteOutlined } from '@ant-design/icons';
import type {
  ComplianceRequirement,
  ComplianceSubject,
} from '@graphql/types/graphql';
import { RequirementComplianceStatus } from '@trustlayer/common';
import {
  RequirementAdditionalNotes,
  RequirementIcon,
  RequirementLabel,
  RequirementPublicNotes,
  RequirementValue,
  Until,
} from '@trustlayer/ui';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

type RequirementProps = {
  requirement: ComplianceRequirement;
  subjectStatus: ComplianceSubject['status'];
  actionsComponent: ReactNode | null;
  onDeletePublicNote: (attributeCode: string) => void;
};

export function Requirement({
  requirement,
  subjectStatus,
  actionsComponent,
  onDeletePublicNote,
}: RequirementProps) {
  return (
    <>
      <StyledRequirementColWithTopPadding>
        <RequirementIcon status={requirement.status} />
      </StyledRequirementColWithTopPadding>
      <StyledRequirementCol>
        <StyledRequirementVerticalSpace>
          <Row justify="space-between" gutter={8} align="top">
            <Col>
              <RequirementLabel
                attributeLabel={requirement.attributeLabel}
                operator={requirement.operator}
                attributeType={requirement.attributeType}
                targetValue={requirement.targetValue}
                custom={Boolean(requirement.custom)}
                matchingCriteria={requirement.matchingCriteria}
                status={requirement.status}
                documentAttributeCode={requirement.documentAttributeCode}
              />
            </Col>
            <Col>
              <Space size="large">
                <RequirementValueWrapper
                  subjectStatus={subjectStatus}
                  resetOn={requirement.resetOn}
                >
                  <RequirementValue
                    value={requirement.value}
                    status={requirement.status}
                    targetValue={requirement.targetValue}
                    resetOn={requirement.resetOn}
                  />
                </RequirementValueWrapper>
                {actionsComponent}
              </Space>
            </Col>
          </Row>
          {requirement.notes && (
            <RequirementAdditionalNotes>
              {requirement.notes}
            </RequirementAdditionalNotes>
          )}
          {requirement.publicNotes && (
            <RequirementPublicNotes
              publicNotes={requirement.publicNotes}
              action={
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    size="small"
                    onClick={() =>
                      onDeletePublicNote(requirement.attributeCode)
                    }
                  />
                </Space>
              }
            />
          )}
        </StyledRequirementVerticalSpace>
      </StyledRequirementCol>
    </>
  );
}

function RequirementValueWrapper({
  subjectStatus,
  resetOn,
  children,
}: Readonly<{
  subjectStatus: ComplianceSubject['status'];
  resetOn: ComplianceRequirement['resetOn'];
  children: React.ReactNode;
}>) {
  const isSubjectedWaivedOrOverridden =
    subjectStatus === RequirementComplianceStatus.Waived ||
    subjectStatus === RequirementComplianceStatus.Overridden;
  const label = isSubjectedWaivedOrOverridden
    ? subjectStatus === RequirementComplianceStatus.Waived
      ? 'Waiver'
      : 'Override'
    : undefined;

  if (isSubjectedWaivedOrOverridden && label) {
    return (
      <Tooltip
        title={`${label} must first be removed on the subject level before editing ${label.toLowerCase()} on the requirement.`}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  return (
    <Until resetOn={resetOn}>
      <div>{children}</div>
    </Until>
  );
}

const StyledRequirementColWithTopPadding = styled(Col)`
  padding-top: 2px;
`;

const StyledRequirementCol = styled(Col)`
  flex: 1
`;

const StyledRequirementVerticalSpace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
