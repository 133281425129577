import { Table, type TableControl } from '@common/components/Table';
import { LinkCell } from '@common/components/Table/components/LinkCell';
import { useDrawerState } from '@common/hooks/useDrawerState';
import { ComplianceProfileEditorWrapper } from '@modules/compliance-profile/containers/ComplianceProfileEditorWrapper';
import { COMPLIANCE_PROFILES_COLUMN_FIELDS } from '@modules/compliance-profile/containers/ComplianceProfilesTable/constants';
import { useMemo } from 'react';
import { EDIT_COMPLIANCE_PROFILE_SEARCH_PARAM } from './constants';
import { useContextRecordComplianceProfile } from './hooks/useContextRecordComplianceProfile';

type ComplianceProfileTableItem = {
  _id: string;
  name: string;
};

export const ComplianceProfilesTable = ({
  contextRecordId,
  tableControl,
}: {
  contextRecordId: string;
  tableControl: TableControl;
}) => {
  const { fetchComplianceProfiles } = useContextRecordComplianceProfile({
    contextRecordId,
  });

  const {
    value: editComplianceProfileId,
    closeDrawer,
    openDrawer,
  } = useDrawerState({
    paramName: EDIT_COMPLIANCE_PROFILE_SEARCH_PARAM,
  });

  const columnDef = useMemo(
    () => [
      {
        field: COMPLIANCE_PROFILES_COLUMN_FIELDS.name,
        headerName: 'Name',
        flex: 1,
        sortable: false,
        resizable: false,
        filter: 'agTextColumnFilter',
        cellRenderer({
          value,
          data,
        }: { value: string; data: ComplianceProfileTableItem }) {
          return (
            <LinkCell onClick={() => openDrawer(data._id)}>{value}</LinkCell>
          );
        },
      },
    ],
    [openDrawer],
  );

  return (
    <>
      <Table
        columnDefs={columnDef}
        getRowData={fetchComplianceProfiles}
        autoHeight
      />
      <ComplianceProfileEditorWrapper
        complianceProfileId={editComplianceProfileId ?? undefined}
        visible={!!editComplianceProfileId}
        onClose={closeDrawer}
        onSuccess={() => {
          closeDrawer();
          tableControl.refetch();
        }}
      />
    </>
  );
};
