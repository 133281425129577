import type { ReactNode } from 'react';
import styled from 'styled-components';

export const DetailsPageHeader = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <StyledHeader className={className}>{children}</StyledHeader>;
};

DetailsPageHeader.TopLeftSection = ({ children }: { children: ReactNode }) => {
  return <div className="top-left-section">{children}</div>;
};

DetailsPageHeader.TopRightSection = ({ children }: { children: ReactNode }) => {
  return <div className="top-right-section">{children}</div>;
};

DetailsPageHeader.BottomLeftSection = ({
  children,
}: { children: ReactNode }) => {
  return <div className="bottom-left-section">{children}</div>;
};

DetailsPageHeader.BottomRightSection = ({
  children,
}: { children: ReactNode }) => {
  return <div className="bottom-right-section">{children}</div>;
};

const StyledHeader = styled.section`
  --grid-tl-area: 1 / 1 / 1 / col3-end;
  --grid-tr-area: 1 / col3-start / 1 / col4-end;
  --grid-br-area: 2 / col3-start / 2 / col4-end;
  --grid-bl-area: 2 / 1 / 2 / col3-end;
  --gap: 8px;

  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto auto;
  gap: 24px;

  .top-left-section {
    grid-area: var(--grid-tl-area);
    gap: var(--gap);
  }

  .top-right-section {
    justify-content: flex-end;
    grid-area: var(--grid-tr-area);
    gap: var(--gap);
  }

  .bottom-left-section {
    justify-content: flex-start;
    gap: var(--gap);
    grid-area: var(--grid-bl-area);
  }

  .bottom-right-section {
    justify-content: flex-end;
    gap: var(--gap);
    grid-area: var(--grid-br-area);
  }

  .top-left-section,
  .top-right-section,
  .bottom-left-section,
  .bottom-right-section {
    display: flex;
    width: 100%;

    &:empty {
      display: none;
    }
  }
`;
