import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const UPDATE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION = graphql(`
  mutation updateRequestRecordRequirementsPublicNotes($data: [UpdateRequestRecordRequirementsPublicNotesInput!]!) {
    updateRequestRecordRequirementsPublicNotes(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useCreateUpdatePublicNote = () => {
  const [updatePublicNote, { error, loading }] = useMutation(
    UPDATE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION,
  );

  const createUpdatePublicNote = async ({
    variables: { attributeCode, id, publicNotes },
  }: {
    variables: {
      attributeCode: string;
      id: string;
      publicNotes: string;
    };
  }) => {
    return await updatePublicNote({
      variables: {
        data: [
          {
            attributeCode,
            id,
            publicNotes,
          },
        ],
      },
    });
  };

  return {
    createUpdatePublicNote,
    loadingCreatePublicNote: loading,
    error,
  };
};
