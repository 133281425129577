import { CollapseCard } from '@common/components/CollapseCard';
import type {
  ComplianceModule,
  ComplianceRequirement,
  ComplianceSubject,
  FillableForm,
} from '@graphql/types/graphql';
import { RuleDisplayValue } from '@modules/compliance-profile/components/RuleDisplayValue';
import { FIELD_ASSOCIATION_TYPES } from '@modules/fillable-form/constants';
import { FillableFormSignContainer } from '@modules/fillable-form/containers/FillableFormSignContainer';
import { HideForViewerRole } from '@modules/organization-member';
import {
  DeletePublicNoteModal,
  Requirement,
  Subject,
  useDeletePublicNoteModalState,
} from '@modules/request-records';
import { REQUEST_RECORD_ACTION_TYPES } from '@modules/request-records';
import { RequestRecordDocumentsChecklist } from '@modules/request-records/components/RequestRecordDocumentsChecklist';
import { RequirementActionsMenu } from '@modules/request-records/components/RequirementActionsMenu/RequirementActionsMenu';
import { getSubjectActions } from '@modules/request-records/components/Subject/Subject.utils';
import { SubjectFillableForm } from '@modules/request-records/components/SubjectFillableForm';
import {
  PublicNoteModal,
  usePublicNoteModalState,
} from '@modules/request-records/containers/PublicNoteModal';
import {
  RemoveWaiveOverrideModal,
  RemoveWaiveOverrideModalActionType,
  RemoveWaiveOverrideModalItemType,
  useRemoveWaiveOverrideModalState,
} from '@modules/request-records/containers/RemoveWaiveOverrideModal';
import {
  WaiveAndOverrideModal,
  WaiveAndOverrideModalItemType,
  getWaiveOverrideActionKey,
  useWaiveOverrideModalState,
} from '@modules/request-records/containers/WaiveAndOverrideModal';
import {
  RequestRecordStatus,
  RequirementComplianceStatus,
} from '@trustlayer/common';
import { ThreeDotsMenu } from '@trustlayer/ui';
import { Empty, Row, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

type SubjectData = {
  code: ComplianceSubject['code'];
  label: string;
  status: ComplianceSubject['status'];
  notes?: ComplianceSubject['notes'];
  resetOn?: ComplianceSubject['resetOn'];
};

type RequirementData = {
  code: ComplianceRequirement['attributeCode'];
  label: string | React.ReactNode;
  status: ComplianceRequirement['status'];
  notes?: ComplianceRequirement['notes'];
  resetOn?: ComplianceRequirement['resetOn'];
  publicNotes?: ComplianceRequirement['publicNotes'];
};

type FillableFormSignModalState =
  | {
      visible: false;
      fillableForm?: FillableForm;
    }
  | {
      visible: true;
      fillableForm: FillableForm;
    };

export const RequestRecordComplianceProfile = ({
  requestRecord,
  primaryRecord,
  complianceModules,
  documentsChecklist,
  disableFillableFormsSign,
  onComplianceChanged,
}: {
  requestRecord: {
    _id: string;
    status: RequestRecordStatus;
    fillableForms?: FillableForm[];
    contextRecord?: {
      _id: string;
      name: string;
    } | null;
  };
  primaryRecord: {
    _id: string;
  };
  complianceModules: ComplianceModule[];
  documentsChecklist: {
    documentType: {
      _id: string;
      name: string;
    };
    attributes: {
      code: string;
      label: string;
    }[];
  }[];
  disableFillableFormsSign?: boolean;
  onComplianceChanged: () => void;
}) => {
  const { publicNoteModalState, setPublicNoteModalState } =
    usePublicNoteModalState();
  const { deletePublicNoteModalState, setDeletePublicNoteModalState } =
    useDeletePublicNoteModalState();
  const { waiveAndOverrideModalState, setWaiveAndOverrideModalState } =
    useWaiveOverrideModalState();

  const { removeWaiverOverrideModalState, setRemoveWaiverOverrideModalState } =
    useRemoveWaiveOverrideModalState();

  const [fillableFormSignModalState, setFillableFormSignModalState] =
    useState<FillableFormSignModalState>({
      visible: false,
    });

  const isRequestRecordArchived =
    requestRecord?.status === RequestRecordStatus.Archived;

  const handleRequirementActionClick = ({
    actionKey,
    requirementData,
  }: {
    actionKey: REQUEST_RECORD_ACTION_TYPES;
    requirementData: RequirementData;
  }) => {
    if (actionKey === REQUEST_RECORD_ACTION_TYPES.addPublicNote) {
      setPublicNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: false,
      });
    }

    if (actionKey === REQUEST_RECORD_ACTION_TYPES.editPublicNote) {
      setPublicNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: true,
      });
    }

    if (
      actionKey === REQUEST_RECORD_ACTION_TYPES.waive ||
      actionKey === REQUEST_RECORD_ACTION_TYPES.override ||
      actionKey === REQUEST_RECORD_ACTION_TYPES.editWaiveOverride
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getWaiveOverrideActionKey(
          actionKey,
          requirementData.status,
        ),
        itemType: WaiveAndOverrideModalItemType.requirement,
        // @ts-expect-error will be fixed once DocumeentRequirement type is not in this file anymore
        itemData: requirementData,
      });
    }

    if (actionKey === REQUEST_RECORD_ACTION_TYPES.removeWaiveOverride) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          requirementData.status === RequirementComplianceStatus.Waived
            ? RemoveWaiveOverrideModalActionType.waive
            : RemoveWaiveOverrideModalActionType.override,
        itemType: RemoveWaiveOverrideModalItemType.requirement,
        itemData: {
          code: requirementData.code,
        },
      });
    }
  };

  const handleSucceededWaiveOverrideModal = () => {
    setWaiveAndOverrideModalState({ visible: false });
    onComplianceChanged();
  };

  const handleSucceededRemoveWaiveOverrideModal = () => {
    setRemoveWaiverOverrideModalState({ visible: false });
    onComplianceChanged();
  };

  const handleSubjectActionClick = ({
    actionKey,
    subjectData,
  }: { actionKey: REQUEST_RECORD_ACTION_TYPES; subjectData: SubjectData }) => {
    if (
      actionKey === REQUEST_RECORD_ACTION_TYPES.waive ||
      actionKey === REQUEST_RECORD_ACTION_TYPES.override ||
      actionKey === REQUEST_RECORD_ACTION_TYPES.editWaiveOverride
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getWaiveOverrideActionKey(actionKey, subjectData.status),
        itemType: WaiveAndOverrideModalItemType.subject,
        itemData: subjectData,
      });
    }

    if (actionKey === REQUEST_RECORD_ACTION_TYPES.removeWaiveOverride) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          subjectData.status === RequirementComplianceStatus.Waived
            ? RemoveWaiveOverrideModalActionType.waive
            : RemoveWaiveOverrideModalActionType.override,
        itemType: RemoveWaiveOverrideModalItemType.subject,
        itemData: {
          code: subjectData.code,
        },
      });
    }
  };

  const handleSucceededPublicNoteModal = () => {
    setPublicNoteModalState({ visible: false });
    onComplianceChanged();
  };

  const handleSucceededDeletePublicNoteModal = () => {
    setDeletePublicNoteModalState({ visible: false });
    onComplianceChanged();
  };

  return (
    <>
      <WaiveAndOverrideModal
        open={waiveAndOverrideModalState.visible}
        actionType={waiveAndOverrideModalState?.actionType!}
        itemType={waiveAndOverrideModalState?.itemType!}
        itemData={waiveAndOverrideModalState?.itemData!}
        requestRecordId={requestRecord._id!}
        onCancel={() => setWaiveAndOverrideModalState({ visible: false })}
        onCompleted={handleSucceededWaiveOverrideModal}
        defaultState={{
          notes: waiveAndOverrideModalState?.itemData?.notes,
          expiringDate: waiveAndOverrideModalState?.itemData?.resetOn,
          status: waiveAndOverrideModalState?.itemData?.status,
        }}
      />
      <RemoveWaiveOverrideModal
        open={removeWaiverOverrideModalState.visible}
        actionType={removeWaiverOverrideModalState.actionType!}
        itemType={removeWaiverOverrideModalState.itemType!}
        itemData={removeWaiverOverrideModalState.itemData!}
        requestRecordId={requestRecord._id!}
        onCancel={() => setRemoveWaiverOverrideModalState({ visible: false })}
        onCompleted={handleSucceededRemoveWaiveOverrideModal}
      />

      <PublicNoteModal
        open={publicNoteModalState.visible}
        isEdit={publicNoteModalState?.isEdit}
        itemData={publicNoteModalState?.itemData!}
        requestRecordId={requestRecord._id!}
        onCancel={() => setPublicNoteModalState({ visible: false })}
        onCompleted={handleSucceededPublicNoteModal}
      />

      <DeletePublicNoteModal
        open={deletePublicNoteModalState.visible}
        itemData={deletePublicNoteModalState.itemData!}
        requestRecordId={requestRecord._id!}
        onCancel={() => setDeletePublicNoteModalState({ visible: false })}
        onCompleted={handleSucceededDeletePublicNoteModal}
      />

      <FillableFormSignContainer
        open={fillableFormSignModalState.visible}
        onClose={() => setFillableFormSignModalState({ visible: false })}
        onSuccess={() => {
          onComplianceChanged();
        }}
        fillableForm={fillableFormSignModalState.fillableForm!}
        primaryRecordId={primaryRecord._id!}
        contextRecord={requestRecord?.contextRecord}
        requestId={requestRecord._id!}
        filler={FIELD_ASSOCIATION_TYPES.REQUESTER}
      />

      {Boolean(complianceModules.length) &&
        complianceModules.map((module) => (
          <CollapseCard key={module.code}>
            <CollapseCard.Header>
              <StyledTitle level={5}>{module.label}</StyledTitle>
            </CollapseCard.Header>
            <CollapseCard.Body>
              {/* SUBJECTS */}
              {module.subjects.length ? (
                <div>
                  {module.subjects.map((subject) => (
                    <Subject
                      key={subject.code}
                      subject={subject}
                      actionsComponent={
                        isRequestRecordArchived ? null : (
                          <HideForViewerRole>
                            <ThreeDotsMenu<REQUEST_RECORD_ACTION_TYPES>
                              items={getSubjectActions(subject.status)}
                              onClick={(actionKey) =>
                                handleSubjectActionClick({
                                  actionKey,
                                  subjectData: {
                                    label: subject.label,
                                    code: subject.code,
                                    status: subject.status,
                                    resetOn: subject.resetOn,
                                    notes: subject.notes,
                                  },
                                })
                              }
                            />
                          </HideForViewerRole>
                        )
                      }
                    >
                      {/* REQUIREMENTS */}
                      {subject.requirements.length > 0 ? (
                        subject.requirements.map((requirement) => (
                          <Row
                            key={requirement.attributeCode}
                            gutter={12}
                            align="top"
                            wrap={false}
                          >
                            <Requirement
                              requirement={requirement}
                              subjectStatus={subject.status}
                              actionsComponent={
                                isRequestRecordArchived ? null : (
                                  <HideForViewerRole>
                                    <RequirementActionsMenu
                                      requirement={requirement}
                                      subjectStatus={subject.status}
                                      onClick={(actionKey) => {
                                        handleRequirementActionClick({
                                          actionKey,
                                          requirementData: {
                                            label: (
                                              <RuleDisplayValue
                                                moduleCode={module.code}
                                                // @ts-expect-error ComplianceRequirement has a lot of fields that are optional, why?
                                                rule={requirement}
                                              />
                                            ),
                                            code: requirement.attributeCode,
                                            publicNotes:
                                              requirement.publicNotes,
                                            status: requirement.status,
                                            resetOn: requirement.resetOn,
                                            notes: requirement.notes,
                                          },
                                        });
                                      }}
                                    />
                                  </HideForViewerRole>
                                )
                              }
                              onDeletePublicNote={(attributeCode: string) =>
                                setDeletePublicNoteModalState({
                                  visible: true,
                                  itemData: {
                                    code: attributeCode,
                                  },
                                })
                              }
                            />
                          </Row>
                        ))
                      ) : (
                        <Empty description="No requirements found for this subject" />
                      )}
                      {!disableFillableFormsSign && (
                        <SubjectFillableForm
                          fillableForm={requestRecord?.fillableForms?.find(
                            (form) => form._id === subject.code,
                          )}
                          onFillClick={(form) =>
                            setFillableFormSignModalState({
                              visible: true,
                              fillableForm: form,
                            })
                          }
                        />
                      )}
                    </Subject>
                  ))}
                </div>
              ) : (
                <Empty description="No requirements found for this module" />
              )}
            </CollapseCard.Body>
          </CollapseCard>
        ))}

      {Boolean(documentsChecklist.length) && (
        <CollapseCard key={'documents-checklist'}>
          <CollapseCard.Header>
            <StyledTitle level={5}>Documents checklist</StyledTitle>
          </CollapseCard.Header>
          <CollapseCard.Body>
            <RequestRecordDocumentsChecklist
              documentsChecklist={documentsChecklist}
            />
          </CollapseCard.Body>
        </CollapseCard>
      )}
    </>
  );
};

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;
