import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PRIMARY_RECORDS_TAGS_QUERY = graphql(`
  query PrimaryRecordsTags(
    $input: PrimaryRecordsTagsInput!
  ) {
    primaryRecordsTags(input: $input) {
      nodes {
        _id
        name
      }
    }
  }
`);

export function usePrimaryRecordsTagsQuery({
  filters,
  skip,
}: { filters?: ServerFilterInput; skip: boolean }) {
  const { data, loading } = useQuery(PRIMARY_RECORDS_TAGS_QUERY, {
    variables: {
      input: {
        filter: filters ?? {
          and: [],
        },
      },
    },
    skip,
  });

  return {
    primaryRecordsTags: data?.primaryRecordsTags?.nodes || [],
    loading,
  };
}
