import styled from 'styled-components';

import { TableHeader } from '@common/components/TableHeader';
import { DetailsPageHeader } from './components/DetailsPageHeader';
import { PageActions } from './components/PageActions';
import { Sidebar } from './components/Sidebar/Sidebar';

type DetailsPageLayoutProps = {
  children: React.ReactNode;
};

export const DetailsPageLayout = ({ children }: DetailsPageLayoutProps) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  --margin: 8px;

  display: flex;
  flex-direction: column;
  position: relative;
  margin: var(--margin);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-shadow: ${({ theme }) => theme.shadows.pageCard};
  min-height: calc(100% - var(--margin) * 2);
  overflow: hidden;
`;

const StyledTopBar = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 8px;
  align-items: center;
`;

const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  flex-grow: 1;
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const StyledBody = styled.div`
  flex-grow: 1;
  position: relative;
`;

const StyledDetailsPageHeader = styled(DetailsPageHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

DetailsPageLayout.Sidebar = Sidebar;
DetailsPageLayout.PageActions = PageActions;
DetailsPageLayout.TopBar = StyledTopBar;
DetailsPageLayout.MainContent = StyledMainContent;
DetailsPageLayout.Header = StyledDetailsPageHeader;
DetailsPageLayout.TableHeader = StyledTableHeader;
DetailsPageLayout.Body = StyledBody;
DetailsPageLayout.Content = StyledContent;
