import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Popover, Skeleton, message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import type { PartialDeep } from 'type-fest';

import { Modal } from '@common/components/Modal';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { useCreateRequestRecordsMutation } from '@modules/request-records/hooks/useCreateRequestRecordsMutation';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { RichSelect } from '../../components/RichSelect';
import { MAX_NEW_REQUESTS_COUNT } from './constants';
import { usePrimaryRecords } from './hooks/usePrimaryRecords';

type CreateContextRecordRequestModalProps = {
  open: boolean;
  contextRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const ALERT_DESCRIPTION =
  'Give your request a unique name. Your request name will be displayed to contacts on the Portal page.';

type FormValues = {
  requests: {
    name: string;
    primaryRecordId: string;
    complianceProfileId: string;
  }[];
};

type RecordTypeConfig = {
  relatedLabel: string;
  placeholder: string;
  initialRequests: PartialDeep<FormValues, { recurseIntoArrays: true }>;
};

const recordTypeConfig: RecordTypeConfig = {
  relatedLabel: 'Party',
  placeholder: 'Select a party',
  initialRequests: {
    requests: [
      {
        name: 'Request 1',
      },
    ],
  },
};

const CreateContextRecordRequestModalInner = ({
  open,
  contextRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: CreateContextRecordRequestModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const [form] = Form.useForm<FormValues>();
  const requests = Form.useWatch(['requests'], form);

  const { createRequestRecords, loading: createRequestRecordsLoading } =
    useCreateRequestRecordsMutation();

  const {
    records,
    recordsTotalCount,
    loading: recordsLoading,
    fetchMoreRecords,
    filterRecordsByName,
  } = usePrimaryRecords();

  const handleOnFormSubmit = async () => {
    try {
      await form.validateFields();
      const { requests } = form.getFieldsValue();

      const res = await createRequestRecords({
        requestRecords: requests,
        recordId: contextRecordId,
        recordType: 'contextRecordId',
      });

      const status = res.data?.createRequestRecords.operation.status;

      if (status === BulkOperationStatus.Completed) {
        message.success(
          `${requests.length} ${pluralize('request', requests.length)} created successfully.`,
        );
        onCompleted?.();
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(
          `${requests.length} ${pluralize('request', requests.length)} have been scheduled for creation.`,
        );
        onScheduled?.();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The creation of ${requests.length} ${pluralize('request', requests.length)} has failed. Please try again or contact support.`,
        );
        onFailed?.();
      }
    } catch (error) {
      message.error('Error: form validation failed.');
      return;
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);

  // Function to handle party selection and automatically set the profile
  const handlePrimaryRecordChange = (value: string, index: number) => {
    const selectedRecord = records.find((record) => record._id === value);

    if (selectedRecord?.type?.defaultComplianceProfile?._id) {
      form.setFieldValue(
        ['requests', index, 'complianceProfileId'],
        selectedRecord.type.defaultComplianceProfile._id,
      );
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledModal
        open={open}
        title="New requests"
        onCancel={() => {
          onCancel?.();
        }}
        okText="Create requests"
        onOk={handleOnFormSubmit}
        okButtonProps={{
          loading: createRequestRecordsLoading,
        }}
      >
        <StyledCreateForm>
          <StyledAlert closable type="info" description={ALERT_DESCRIPTION} />

          <div className="row header">
            <span>Request name</span>
            <span>{recordTypeConfig.relatedLabel}</span>
            <span>
              {isComplianceRequirementsAddOnEnabled ? 'Profile' : 'Checklist'}
            </span>
          </div>

          <Form
            name="dynamicRequestsForm"
            autoComplete="off"
            form={form}
            initialValues={recordTypeConfig.initialRequests}
          >
            <Form.List name="requests">
              {(fields, { add, remove }) => (
                <>
                  <StyledList>
                    {fields.length === 0 && recordsLoading && (
                      <Skeleton.Input
                        block
                        active
                        style={{ marginBottom: 12 }}
                      />
                    )}
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="row">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input a valid request name.',
                            },
                          ]}
                        >
                          <Input placeholder="Request name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'primaryRecordId']}
                          rules={[
                            {
                              required: true,
                              message: 'This input is required',
                            },
                          ]}
                        >
                          <RichSelect
                            optionsList={records}
                            optionsTotalCount={recordsTotalCount}
                            loading={recordsLoading}
                            placeholder={recordTypeConfig.placeholder}
                            onSearch={filterRecordsByName}
                            onLoadMore={fetchMoreRecords}
                            onChange={(value) =>
                              handlePrimaryRecordChange(value, name)
                            }
                          >
                            {(option) => <span>{option.name}</span>}
                          </RichSelect>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'complianceProfileId']}
                          getValueFromEvent={(profile) => profile._id}
                        >
                          <Popover
                            content={
                              <span>
                                Select a party before choosing a profile
                              </span>
                            }
                            open={
                              //? Workaround to prevent the popover from being open when the primary record is not selected
                              !!requests?.[name]?.primaryRecordId
                                ? false
                                : undefined
                            }
                          >
                            <ComplianceProfilesSelect
                              disabled={!requests?.[name]?.primaryRecordId}
                              size={'middle'}
                              placeholder={
                                isComplianceRequirementsAddOnEnabled
                                  ? 'Select a Compliance Profile'
                                  : 'Select a Checklist'
                              }
                              value={requests?.[name]?.complianceProfileId}
                              onChange={(value) => {
                                form.setFieldValue(
                                  ['requests', name, 'complianceProfileId'],
                                  value._id,
                                );
                              }}
                            />
                          </Popover>
                        </Form.Item>

                        <CloseOutlined
                          className="delete"
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                  </StyledList>
                  <Form.Item>
                    <Button
                      type="dashed"
                      disabled={fields.length >= MAX_NEW_REQUESTS_COUNT}
                      onClick={() =>
                        add({
                          name: `Request ${fields.length + 1}`,
                          related: undefined,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add request
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </StyledCreateForm>
      </StyledModal>
    </>
  );
};

export const CreateContextRecordRequestModal = withControlledMount(
  CreateContextRecordRequestModalInner,
);

const StyledModal = styled(Modal)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledCreateForm = styled.section`
  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) 24px;
    align-items: baseline;
    gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  }

  .delete {
    grid-column: span 1 / -1;
  }

  .header {
    font-weight: bold;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  padding: 9px 16px;
  border: none;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

const StyledList = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const GlobalStyle = createGlobalStyle`
  .item-icon {
    margin-right: 5px;
  }
`;
