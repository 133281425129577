import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REMOVE_TAGS_FROM_PRIMARY_RECORDS_MUTATION = graphql(`
  mutation RemoveTagsFromPrimaryRecords($data: RemoveTagsFromPrimaryRecordsInput!) {
    removeTagsFromPrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useRemoveTagsFromPrimaryRecords = () => {
  const [removeTagsFromPrimaryRecordsMutation, { loading }] = useMutation(
    REMOVE_TAGS_FROM_PRIMARY_RECORDS_MUTATION,
  );

  const removeTagsFromPrimaryRecords = ({
    tagIds,
    filters,
  }: {
    tagIds: string[];
    filters?: ServerFilterInput;
  }) => {
    return removeTagsFromPrimaryRecordsMutation({
      variables: {
        data: {
          tagIds,
          ...(filters && { filter: filters }),
        },
      },
    });
  };

  return { removeTagsFromPrimaryRecords, loading };
};
