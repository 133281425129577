import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useParams,
  useSearchParams,
} from 'react-router';

import Preloader from '@common/components/Preloader';
import useEntityQueryString from '@common/hooks/useEntityQueryString';
import { lazyWithRetry } from '@common/utils/lazyWithRetry';
import { useIsBaseAddOnEnabled } from '@modules/add-on';
import PrivateRouteGuardLayout from '@modules/auth/components/PrivateRouteGuardLayout';
import RequestLandingGuardLayout from '@modules/auth/components/RequestLandingGuardLayout';
import AuthenticationGuard from '@modules/auth/containers/AuthenticationGuard';
import { getIsAuthenticated } from '@modules/auth/selectors';
import { CONTEXT_RECORDS_TABS } from '@modules/context-records/constants';
import { useTrustLayerV2FeatureFlag } from '@modules/feature-flags/hooks';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { PRIMARY_RECORDS_TABS } from '@modules/primary-records';
import { REQUEST_RECORDS_TABS } from '@modules/request-records';
import ContextObjectPage from '@pages/ContextObject';
import {
  ContextRecordAttachments,
  ContextRecordAttributes,
  ContextRecordComplianceProfiles,
  ContextRecordDetail,
  ContextRecordDocuments,
  ContextRecordRequests,
} from '@pages/ContextRecordDetail';
import PrimaryObjectPage from '@pages/PrimaryObject';
import {
  PrimaryRecordActivityTimeline,
  PrimaryRecordAttributes,
  PrimaryRecordContacts,
  PrimaryRecordDetail,
  PrimaryRecordDocuments,
  PrimaryRecordMessages,
  PrimaryRecordRequests,
} from '@pages/PrimaryRecordDetail';
import {
  RequestRecordAttributes,
  RequestRecordChecklist,
  RequestRecordCompliance,
  RequestRecordDetail,
} from '@pages/RequestRecordDetail';
import { RequestRecordDetailGuard } from '@pages/RequestRecordDetail/RequestRecordDetailGuard';
import { PageLayout } from 'layouts';
import RootLayout from './components/RootLayout';
import UncaughtRouterError from './components/UncaughtRouterError';
import UserLayout from './components/UserLayout';
import { PrimaryRecordPageGuard } from './containers/PrimaryRecordPageGuard';
import { WorkspacePageGuard } from './containers/WorkspacePageGuard';

const LoginPage = lazyWithRetry(() => import('@modules/auth/pages/LoginPage'));

const AuthActionPage = lazyWithRetry(
  () => import('@modules/auth/pages/AuthActionPage'),
);

const AcceptInvitePage = lazyWithRetry(
  () => import('@modules/auth/pages/AcceptInvitePage'),
);

const ForgotPasswordPage = lazyWithRetry(
  () => import('@modules/auth/pages/ForgotPasswordPage'),
);

const ResetPasswordPage = lazyWithRetry(
  () => import('@modules/auth/pages/ResetPasswordPage'),
);

const LogoutPage = lazyWithRetry(
  () => import('@modules/auth/pages/LogoutPage'),
);

const DocumentsPage = lazyWithRetry(() => import('@pages/Documents'));

const DocumentReviewPageLegacy = lazyWithRetry(
  () => import('@modules/document/pages/DocumentReviewPage.legacy'),
);

const DocumentReviewPage = lazyWithRetry(
  () => import('@modules/document/pages/DocumentReviewPage'),
);

const PartiesListPage = lazyWithRetry(
  () => import('@modules/party/pages/PartiesPage'),
);

const PartyEditPage = lazyWithRetry(
  () => import('@modules/party/pages/PartyEditPage'),
);

const PartyDetailsPage = lazyWithRetry(
  () => import('@modules/party/pages/PartyDetailsPage'),
);

const ProjectsPage = lazyWithRetry(
  () => import('@modules/project/pages/ProjectsPage'),
);

const RequestTokenPage = lazyWithRetry(
  () => import('@modules/request/pages/RequestTokenPage'),
);

const RequestLandingPage = lazyWithRetry(
  () => import('@modules/request/pages/RequestLandingPage'),
);

const SettingPage = lazyWithRetry(
  () => import('@modules/setting/pages/SettingsPage'),
);

const DashboardPage = lazyWithRetry(
  () => import('@modules/dashboard/pages/DashboardPage'),
);

const QuickbooksCallbackPage = lazyWithRetry(
  () => import('@modules/quickbooks/pages/QuickbooksCallbackPage'),
);

const QuickbooksDisconnectedPage = lazyWithRetry(
  () => import('@modules/quickbooks/pages/QuickbooksDisconnectedPage'),
);

const NotFoundPage = lazyWithRetry(
  () => import('@modules/landing/pages/NotFoundPage'),
);

const ProbePage = lazyWithRetry(() => import('@modules/app/pages/ProbePage'));

const FillableFormPage = lazyWithRetry(
  () => import('@pages/Settings/FillableFormPage'),
);

const BrowserNotSupportedPage = lazyWithRetry(
  () => import('@modules/app/pages/BrowserNotSupportedPage'),
);

const AutomationEditPage = lazyWithRetry(
  () =>
    import('@modules/automations/pages/AutomationEditPage/AutomationEditPage'),
);

const ExternalDashboardsPage = lazyWithRetry(
  () => import('@modules/external-dashboards/pages/MetabaseDashboard'),
);

const RootRedirect = () => {
  const isAuth = useSelector(getIsAuthenticated);

  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to="/dashboard" replace />;
};

const NewObjectsRedirect = ({ objectSlug, objectType, DefaultComponent }) => {
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();
  const organization = useSelector((state) => getActiveOrganizationData(state));

  return isTlV2FeatureFlagEnabled ? (
    <Navigate to={`/${organization.slugifyName}/${objectType}/${objectSlug}`} />
  ) : (
    <DefaultComponent />
  );
};

const ContextRecordsFFWrapper = (params) => {
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();

  const { DefaultComponent } = params;

  return isTlV2FeatureFlagEnabled ? (
    <NewObjectsRedirect {...params} />
  ) : (
    <DefaultComponent />
  );
};

const DocumentReviewRedirect = () => {
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();
  const organization = useSelector((state) => getActiveOrganizationData(state));
  const { partyId, documentId } = useParams();
  const [searchParams] = useSearchParams();

  if (!isTlV2FeatureFlagEnabled) {
    return <DocumentReviewPageLegacy />;
  }

  const documentsReviewSegment = documentId
    ? `documents-review/${documentId}`
    : 'documents-review';

  return (
    <Navigate
      replace
      to={`/${organization.slugifyName}/p/parties/${partyId}/${documentsReviewSegment}?${searchParams}`}
    />
  );
};

export const DashboardPageGuard = () => {
  const { organizationSlugifyName } = useParams();
  const { getQsForEntityList } = useEntityQueryString();
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  if (isBaseAddOnEnabled)
    return (
      <Navigate
        replace
        to={`/${organizationSlugifyName}/parties?${getQsForEntityList(
          'parties',
        )}`}
      />
    );

  return <DashboardPage />;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      HydrateFallback: Preloader,
      element: <RootLayout />,
      errorElement: <UncaughtRouterError />,
      children: [
        {
          path: 'request-token/:token',
          element: <RequestTokenPage />,
        },
        {
          path: 'request-landing/:requestId',
          element: <RequestLandingGuardLayout />,
          children: [
            {
              path: '',
              element: <RequestLandingPage />,
            },
          ],
        },
        {
          path: '',
          element: <UserLayout />,
          children: [
            {
              index: true,
              element: <RootRedirect />,
            },
            {
              path: 'login',
              element: <LoginPage />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPasswordPage />,
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            {
              path: 'logout',
              element: <LogoutPage />,
            },
            {
              path: 'auth-action',
              element: <AuthActionPage />,
            },
            {
              path: 'accept-invite',
              element: <AcceptInvitePage />,
            },
            {
              path: ':organizationSlugifyName',
              element: <Outlet />,
              children: [
                {
                  path: 'forgot-password',
                  element: <ForgotPasswordPage />,
                },
                {
                  path: 'login',
                  element: <LoginPage />,
                },
              ],
            },
            {
              path: 'quickbooks/callback',
              element: <PrivateRouteGuardLayout />,
              children: [
                {
                  path: '',
                  element: <QuickbooksCallbackPage />,
                },
              ],
            },
            {
              path: 'quickbooks/disconnected',
              element: <QuickbooksDisconnectedPage />,
            },
            {
              path: 'dashboard',
              element: <PrivateRouteGuardLayout redirect />,
            },
            {
              path: 'workspaces',
              element: <AuthenticationGuard />,
              children: [
                {
                  path: '',
                  element: <WorkspacePageGuard />,
                },
              ],
            },
            {
              path: ':organizationSlugifyName',
              element: <PrivateRouteGuardLayout />,
              children: [
                {
                  path: '',
                  element: <PageLayout />,
                  children: [
                    {
                      path: '',
                      element: <Navigate to="dashboard" replace />,
                    },
                    {
                      path: 'projects',
                      element: <Outlet />,
                      children: [
                        {
                          path: '',
                          element: (
                            <ContextRecordsFFWrapper
                              objectType={'c'}
                              objectSlug={'projects'}
                              DefaultComponent={ProjectsPage}
                            />
                          ),
                        },
                      ],
                    },
                    {
                      path: 'parties',
                      element: <Outlet />,
                      children: [
                        {
                          path: '',
                          element: (
                            <NewObjectsRedirect
                              objectType={'p'}
                              objectSlug={'parties'}
                              DefaultComponent={PartiesListPage}
                            />
                          ),
                        },
                        {
                          path: 'new',
                          element: <PartyEditPage />,
                        },
                        {
                          path: ':id/edit',
                          element: <PartyEditPage />,
                        },
                        {
                          path: ':partyId/:tab',
                          element: <PrimaryRecordPageGuard />,
                        },
                        {
                          path: ':partyId/:tab/:conversationId',
                          element: <PrimaryRecordPageGuard />,
                        },
                      ],
                    },
                    {
                      path: 'documents',
                      element: <DocumentsPage />,
                    },
                    {
                      path: 'dashboard',
                      element: <DashboardPageGuard />,
                    },
                    {
                      path: 'p/:objectSlug',
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: <PrimaryObjectPage />,
                        },
                        {
                          path: ':recordId',
                          element: <PrimaryRecordDetail />,
                          children: [
                            {
                              path: PRIMARY_RECORDS_TABS.Requests.key,
                              element: <PrimaryRecordRequests />,
                            },
                            {
                              path: PRIMARY_RECORDS_TABS.Activity.key,
                              element: <PrimaryRecordActivityTimeline />,
                            },
                            {
                              path: PRIMARY_RECORDS_TABS.Documents.key,
                              element: <PrimaryRecordDocuments />,
                            },
                            {
                              path: PRIMARY_RECORDS_TABS.Attributes.key,
                              element: <PrimaryRecordAttributes />,
                            },
                            {
                              path: PRIMARY_RECORDS_TABS.Contacts.key,
                              element: <PrimaryRecordContacts />,
                            },
                            {
                              path: PRIMARY_RECORDS_TABS.Messages.key,
                              element: <PrimaryRecordMessages />,
                              children: [
                                {
                                  path: ':conversationId',
                                  element: <PrimaryRecordMessages />,
                                },
                              ],
                            },
                            {
                              path: '*',
                              index: true,
                              element: (
                                <Navigate
                                  to={PRIMARY_RECORDS_TABS.Requests.key}
                                  replace
                                />
                              ),
                            },
                          ],
                        },
                        {
                          path: ':recordId/requests/:requestId',
                          element: <RequestRecordDetail />,
                          children: [
                            {
                              path: REQUEST_RECORDS_TABS.Compliance.key,
                              element: <RequestRecordCompliance />,
                            },
                            {
                              path: REQUEST_RECORDS_TABS.Attributes.key,
                              element: <RequestRecordAttributes />,
                            },
                            {
                              path: REQUEST_RECORDS_TABS.Checklist.key,
                              element: <RequestRecordChecklist />,
                            },
                            {
                              path: '*',
                              index: true,
                              element: <RequestRecordDetailGuard />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: 'c/:objectSlug',
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: <ContextObjectPage />,
                        },
                        {
                          path: ':recordId',
                          element: <ContextRecordDetail />,
                          children: [
                            {
                              path: CONTEXT_RECORDS_TABS.Requests.key,
                              element: <ContextRecordRequests />,
                            },
                            {
                              path: CONTEXT_RECORDS_TABS.Documents.key,
                              element: <ContextRecordDocuments />,
                            },
                            {
                              path: CONTEXT_RECORDS_TABS.Attributes.key,
                              element: <ContextRecordAttributes />,
                            },
                            {
                              path: CONTEXT_RECORDS_TABS.Attachments.key,
                              element: <ContextRecordAttachments />,
                            },
                            {
                              path: CONTEXT_RECORDS_TABS.ComplianceProfiles.key,
                              element: <ContextRecordComplianceProfiles />,
                            },
                            {
                              path: '*',
                              index: true,
                              element: (
                                <Navigate
                                  to={CONTEXT_RECORDS_TABS.Requests.key}
                                  replace
                                />
                              ),
                            },
                          ],
                        },
                        {
                          path: ':contextId/requests/:requestId',
                          element: <RequestRecordDetail />,
                          children: [
                            {
                              path: REQUEST_RECORDS_TABS.Compliance.key,
                              element: <RequestRecordCompliance />,
                            },
                            {
                              path: REQUEST_RECORDS_TABS.Attributes.key,
                              element: <RequestRecordAttributes />,
                            },
                            {
                              path: REQUEST_RECORDS_TABS.Checklist.key,
                              element: <RequestRecordChecklist />,
                            },
                            {
                              path: '*',
                              index: true,
                              element: <RequestRecordDetailGuard />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: 'reports',
                      element: <ExternalDashboardsPage />,
                    },
                  ],
                },
                {
                  path: 'parties',
                  element: <Outlet />,
                  children: [
                    {
                      path: ':partyId/documents-review',
                      element: <DocumentReviewRedirect />,
                    },
                    {
                      path: ':partyId/documents-review/:documentId',
                      element: <DocumentReviewRedirect />,
                    },
                  ],
                },
                {
                  path: 'p/:objectSlug',
                  element: <Outlet />,
                  children: [
                    {
                      path: ':primaryRecordId/documents-review',
                      element: <DocumentReviewPage />,
                    },
                    {
                      path: ':primaryRecordId/documents-review/:documentId',
                      element: <DocumentReviewPage />,
                    },
                  ],
                },
                {
                  path: 'settings',
                  element: <Outlet />,
                  children: [
                    {
                      path: 'fillable-forms/:fillableFormId',
                      element: <FillableFormPage />,
                    },
                    {
                      path: 'automations/:automationId',
                      element: <AutomationEditPage />,
                    },
                    {
                      path: 'projects',
                      element: (
                        <Navigate to="../custom-fields/project" replace />
                      ),
                    },
                    {
                      path: '*',
                      element: <SettingPage />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'unsupported-browser',
          element: <BrowserNotSupportedPage />,
        },
        {
          path: 'probe',
          element: <ProbePage />,
        },
        {
          path: '404',
          element: <NotFoundPage />,
        },
        {
          path: 'not-found',
          element: <NotFoundPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export default function Router() {
  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
