import { Alert, type ButtonProps, Drawer, Modal, message } from 'antd';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import FillDocumentForm from '@modules/document/components/FillDocumentForm';
import FillDocumentFormLegacy from '@modules/document/components/FillDocumentForm.legacy';
import { useSaveProgressOnFillSignFeatureFlag } from '@modules/feature-flags/hooks';

import type {
  ContextRecord,
  Document,
  UpdateDocumentFormInput,
} from '@graphql/types/graphql';
import { useEffect, useReducer } from 'react';
import { FIELD_ASSOCIATION_TYPES } from '../constants';
import SignAgreement from './SignAgreement';

export type DocumentFormToSubmit = Pick<
  UpdateDocumentFormInput,
  'fillableForm' | 'saveType' | 'signerName' | '_id'
>;

type ReducerState = {
  isSubmitting: boolean;
  isSavingDraft: boolean;
  isSaveDraftModalOpened: boolean;
  openedDocumentForm?: Document;
  documentFormToSubmit?: DocumentFormToSubmit;
  isSubmitAgreementModalOpened: boolean;
  submissionFailedMessage: boolean | string;
  isSubmitCompletedModalOpened: boolean;
  isFormInSignAgreementModalVisible: boolean;
  signer?: { name: string; email: string };
};

type Reducer = (
  state: ReducerState,
  newState: Partial<ReducerState>,
) => ReducerState;

const reducer: Reducer = (state, newState = {}) => ({
  ...state,
  ...newState,
});

type FillableFormSignDrawerProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  filler: string;
  signer?: { name: string; email: string };
  contextRecord?: Pick<ContextRecord, '_id' | 'name'> | null;
  documentForm?: Document;
  loading: boolean;
  onUpdateDocumentForm: (documentForm: DocumentFormToSubmit) => Promise<any>;
};

export const FillableFormSignDrawer = ({
  open,
  onClose,
  signer: initialSigner,
  filler,
  contextRecord,
  documentForm,
  loading,
  onUpdateDocumentForm,
  onSuccess,
}: FillableFormSignDrawerProps) => {
  const { isSaveProgressOnFillSignFeatureFlagEnabled } =
    useSaveProgressOnFillSignFeatureFlag();

  const [
    {
      isSubmitting,
      isSavingDraft,
      isSaveDraftModalOpened,
      documentFormToSubmit,
      isSubmitAgreementModalOpened,
      openedDocumentForm,
      submissionFailedMessage,
      isSubmitCompletedModalOpened,
      isFormInSignAgreementModalVisible,
      signer,
    },
    setState,
  ] = useReducer<Reducer>(reducer, {
    isSubmitting: false,
    isSavingDraft: false,
    isSaveDraftModalOpened: false,
    openedDocumentForm: undefined,
    documentFormToSubmit: undefined,
    isSubmitAgreementModalOpened: false,
    submissionFailedMessage: false,
    isSubmitCompletedModalOpened: false,
    isFormInSignAgreementModalVisible: false,
    signer: initialSigner,
  });

  useEffect(() => {
    //? reset openedDocumentForm when loading to avoid stale data rendered in the document renderer
    if (!documentForm || loading) {
      setState({ openedDocumentForm: undefined });
      return;
    }
    //? deep clone because PDFKit mutates this object
    setState({ openedDocumentForm: JSON.parse(JSON.stringify(documentForm)) });
  }, [documentForm, loading]);

  const handleOnDocumentFormSaveDraft = (data: DocumentFormToSubmit) => {
    setState({
      documentFormToSubmit: data,
      isSaveDraftModalOpened: true,
    });
  };

  const handleOnDocumentFormSubmit = (data: DocumentFormToSubmit) => {
    setState({
      documentFormToSubmit: data,
      isSubmitAgreementModalOpened: true,
    });
  };

  const handleOnSubmitModalAgreement = async () => {
    try {
      setState({ isSubmitting: true });

      await onUpdateDocumentForm(documentFormToSubmit!);

      setState({
        isSubmitAgreementModalOpened: false,
        isSubmitting: false,
        isSubmitCompletedModalOpened: true,
      });
    } catch (err) {
      setState({
        isSubmitAgreementModalOpened: false,
        isSubmitting: false,
        submissionFailedMessage: (err as Error).message,
      });
    }
  };

  const handleOnConfirmSaveDraft = async () => {
    try {
      setState({ isSavingDraft: true });

      await onUpdateDocumentForm(documentFormToSubmit!);

      setState({
        isSaveDraftModalOpened: false,
        isSavingDraft: false,
      });

      handleOnCloseDrawer();
    } catch (err) {
      setState({
        isSaveDraftModalOpened: false,
        isSavingDraft: false,
        submissionFailedMessage: (err as Error).message,
      });
    }
  };

  const handleOnCloseDrawer = () => {
    onClose();
    return new Promise((res) => {
      setState({
        isSubmitCompletedModalOpened: false,
      });

      // wait drawer animation
      setTimeout(res, 300);
    });
  };

  const handleOnCloseDrawerAfterSubmit = () => {
    handleOnCloseDrawer().then(() => {
      message.success(
        'Thank you for completing. This document is being digitally signed.',
      );
      onSuccess?.();
    });
  };

  const handleOnSignerChange = (signer: ReducerState['signer']) =>
    setState({ signer });

  return (
    <>
      <StyledDrawer
        closable={false}
        open={open}
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          width: '950px',
        }}
      >
        {!openedDocumentForm || loading ? (
          <Spinner />
        ) : isSaveProgressOnFillSignFeatureFlagEnabled ? (
          <FillDocumentForm
            documentForm={openedDocumentForm}
            project={contextRecord}
            filler={filler}
            onBackButtonClick={handleOnCloseDrawer}
            onSubmit={handleOnDocumentFormSubmit}
            onSaveDraft={handleOnDocumentFormSaveDraft}
          />
        ) : (
          <FillDocumentFormLegacy
            documentForm={openedDocumentForm}
            project={contextRecord}
            filler={filler}
            onBackButtonClick={handleOnCloseDrawer}
            onSubmit={handleOnDocumentFormSubmit}
          />
        )}
      </StyledDrawer>
      <Modal
        open={isSaveDraftModalOpened}
        onOk={handleOnConfirmSaveDraft}
        onCancel={() => setState({ isSaveDraftModalOpened: false })}
        confirmLoading={isSavingDraft}
        title="Fill & Sign is not complete"
        okText="Save"
        cancelText="Cancel"
        closable={!isSavingDraft}
        maskClosable={!isSavingDraft}
        cancelButtonProps={{ disabled: isSavingDraft }}
        destroyOnClose
      >
        Do you want to save your progress and complete at a later time?
      </Modal>
      <Modal
        open={isSubmitAgreementModalOpened}
        onOk={handleOnSubmitModalAgreement}
        onCancel={() => setState({ isSubmitAgreementModalOpened: false })}
        confirmLoading={isSubmitting}
        title="Almost done"
        okText="I agree"
        okButtonProps={
          {
            'data-cy': 'documentFormSubmitAgreeButton',
            disabled:
              isFormInSignAgreementModalVisible ||
              (filler === FIELD_ASSOCIATION_TYPES.PARTY && !signer?.name),
          } as ButtonProps
        }
        zIndex={1005}
        closable={!isSubmitting}
        maskClosable={!isSubmitting}
        cancelButtonProps={{ disabled: isSubmitting }}
        destroyOnClose
      >
        {isSubmitting ? (
          <Spinner />
        ) : (
          <SignAgreement
            signer={signer}
            verifySigner={filler === FIELD_ASSOCIATION_TYPES.PARTY}
            onSignerChange={handleOnSignerChange}
            onFormVisibilityChange={(isVisible: boolean) =>
              setState({
                isFormInSignAgreementModalVisible: isVisible,
              })
            }
          />
        )}
      </Modal>
      <Modal
        open={isSubmitCompletedModalOpened}
        title="Form successfully completed"
        okText="Close"
        // @ts-ignore
        okType="secondary"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOnCloseDrawerAfterSubmit}
        onCancel={handleOnCloseDrawerAfterSubmit}
        zIndex={1005}
      >
        <Alert
          message={openedDocumentForm?.friendlyName}
          description="You’ve completed all of your fields in this document."
          type="success"
          showIcon
        />
      </Modal>
      <Modal
        open={Boolean(submissionFailedMessage)}
        title="Form submission failed"
        okText="Close"
        // @ts-ignore
        okType="secondary"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setState({ submissionFailedMessage: false })}
        onCancel={() => setState({ submissionFailedMessage: false })}
        zIndex={1005}
      >
        <Alert
          message={openedDocumentForm?.friendlyName}
          description={
            submissionFailedMessage ||
            'The submission failed with an error. Please try again.'
          }
          type="error"
          showIcon
        />
      </Modal>
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  z-index: 1002;

  .ant-drawer-content-wrapper {
    max-width: 90%;

    @media (max-width: 450px) {
      max-width: 100%;
    }
  }
`;
