import { ClockCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { TagRounded } from '@common/components/TagRounded';

import { TAG_COLORS } from '../../constants';

import { CloseIconWithConfirmation } from './components/CloseIconWithConfirmation';

type TagProps = {
  label: string;
  color?: keyof typeof TAG_COLORS;
  expiresAt?: string;
  onClose?: () => void;
  closeConfirmText?: string | React.ReactNode;
};

export const Tag = ({
  label,
  color,
  onClose,
  closeConfirmText,
  expiresAt,
}: TagProps) => {
  return (
    <StyledTag
      closeIcon={
        closeConfirmText &&
        onClose && (
          <CloseIconWithConfirmation
            closeConfirmText={closeConfirmText}
            onClose={onClose}
          />
        )
      }
      closable={!!onClose}
      onClose={onClose}
      data-cy="partyTag"
      color={color}
    >
      {expiresAt && (
        <Tooltip title={`Tag expires on ${moment(expiresAt).format('ll')}`}>
          <ClockCircleOutlined
            data-cy="expiresAtClock"
            style={{ marginRight: 5 }}
          />
        </Tooltip>
      )}
      {label}
    </StyledTag>
  );
};

const StyledTag = styled(TagRounded)<{
  color?: keyof typeof TAG_COLORS | null;
}>`
  color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { text: '#5077a7' }).text} !important;
  border-color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { border: '#c5d6ec' }).border} !important;
  background-color: ${({ color }) =>
    (color ? TAG_COLORS[color] : { background: '#f4f8fd' }).background};
  margin-inline-end: 0;

  .anticon-close,
  .anticon-close:hover {
    color: ${({ color }) =>
      (color ? TAG_COLORS[color] : { text: '#5077a7' }).text} !important;
  }
`;
