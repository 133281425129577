import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

type Tag = {
  _id?: string;
  name: string;
};

/**
 * @note Removing the name field from the existing tags enabling the BE to distinguish between
 * the new tags and the existing tags.
 */
const getAPITagPayload = (tags: Tag[]) => {
  return tags.map((tag) => {
    if (tag._id) {
      return { id: tag._id };
    }

    return { name: tag.name };
  });
};

const ADD_TAGS_TO_PRIMARY_RECORDS_MUTATION = graphql(`
  mutation AddTagsToPrimaryRecords($data: AddTagsToPrimaryRecordsInput!) {
    addTagsToPrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useAddTagsToPrimaryRecords = () => {
  const [addTagsToPrimaryRecordsMutation, { loading }] = useMutation(
    ADD_TAGS_TO_PRIMARY_RECORDS_MUTATION,
  );

  const addTagsToPrimaryRecords = (
    tags: Tag[],
    filters?: ServerFilterInput,
  ) => {
    return addTagsToPrimaryRecordsMutation({
      variables: {
        data: {
          ...(filters && { filter: filters }),
          tags: getAPITagPayload(tags),
        },
      },
    });
  };

  return { addTagsToPrimaryRecords, loading };
};
