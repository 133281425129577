import { FILTER_OPERATORS } from '@common/constants/filters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  PrimaryRecordsForRequestQuery,
  PrimaryRecordsForRequestQueryVariables,
} from '@graphql/types/graphql';
import { ContextRecordStatus } from '@trustlayer/common';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;

const ACTIVE_RECORDS_FILTER = {
  name: 'status',
  operator: FILTER_OPERATORS.in,
  value: [ContextRecordStatus.Active],
};

const PRIMARY_RECORDS_FOR_REQUEST_QUERY = graphql(`
  query PrimaryRecordsForRequest($input: PrimaryRecordsInput) {
    primaryRecords(input: $input) {
      nodes {
        _id
        name
        type {
          defaultComplianceProfile {
            _id
          }
        }
      }
      totalCount
    }
  }
`);

export type UsePrimaryRecordsProps = {
  skip?: boolean;
};

export const usePrimaryRecords = ({
  skip = false,
}: UsePrimaryRecordsProps = {}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data, loading, fetchMore } = useQuery<
    PrimaryRecordsForRequestQuery,
    PrimaryRecordsForRequestQueryVariables
  >(PRIMARY_RECORDS_FOR_REQUEST_QUERY, {
    variables: {
      input: {
        first: DEFAULT_PAGE_SIZE,
        offset: 0,
        filter: {
          and: [
            ACTIVE_RECORDS_FILTER,
            ...(searchTerm
              ? [
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: searchTerm,
                  },
                ]
              : []),
          ],
        },
      },
    },
    fetchPolicy: 'network-only',
    skip,
  });

  const records = data?.primaryRecords.nodes || [];
  const recordsTotalCount = data?.primaryRecords.totalCount || 0;

  const filterRecordsByName = useMemo(
    () =>
      debounce((name: string) => {
        setSearchTerm(name);
      }, 500),
    [],
  );

  const fetchMoreRecords = useCallback(() => {
    fetchMore({
      variables: {
        input: {
          first: DEFAULT_PAGE_SIZE,
          offset: records.length,
          filter: {
            and: [
              ACTIVE_RECORDS_FILTER,
              ...(searchTerm
                ? [
                    {
                      name: 'name',
                      operator: FILTER_OPERATORS.contains,
                      value: searchTerm,
                    },
                  ]
                : []),
            ],
          },
        },
      },
    });
  }, [fetchMore, records.length, searchTerm]);

  return {
    records,
    recordsTotalCount,
    loading,
    filterRecordsByName,
    fetchMoreRecords,
  };
};
