import { PoweroffOutlined } from '@ant-design/icons';
import PaperPlaneIcon from '@common/icons/PaperPlane';
import { type ActionType, PRIMARY_RECORDS_ACTION_TYPES } from '../constants';

type Params = {
  excludedActions?: ActionType[];
  options?: Record<string, boolean>;
};

export const getActionsMenuItems = ({
  excludedActions = [],
  options,
}: Params) => {
  const actions = [
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.sendEmails,
      testId: PRIMARY_RECORDS_ACTION_TYPES.sendEmails,
      label: 'Send emails',
      icon: (
        <i className="anticon">
          <PaperPlaneIcon />
        </i>
      ),
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.archive,
      label: 'Archive',
      icon: <PoweroffOutlined />,
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.makeActive,
      label: 'Make active',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
      testId: PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
      label: 'Enable automation',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.disableAutomation,
      testId: PRIMARY_RECORDS_ACTION_TYPES.disableAutomation,
      label: 'Disable automation',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.removeTags,
      testId: PRIMARY_RECORDS_ACTION_TYPES.removeTags,
      label: 'Remove tags',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.addTags,
      testId: PRIMARY_RECORDS_ACTION_TYPES.addTags,
      label: 'Add tags',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.setDefaultContacts,
      testId: PRIMARY_RECORDS_ACTION_TYPES.setDefaultContacts,
      label: 'Enable all contacts for emails',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.delete,
      testId: PRIMARY_RECORDS_ACTION_TYPES.delete,
      label: 'Delete',
      danger: true,
    },
  ];

  return actions.filter(
    (action) => excludedActions.includes(action.code) === false,
  );
};
