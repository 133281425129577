import { FullWidthSpace } from '@trustlayer/ui';
import { Typography, message } from 'antd';
import type { EditorState } from 'draft-js';
import { useState } from 'react';

import { Modal } from '@common/components/Modal';
import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  isEditorStateBlank,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { useCreateUpdatePublicNote } from './hooks/useCreateUpdatePublicNote';

type PublicNoteModalProps = {
  open: boolean;
  isEdit?: boolean;
  itemData: {
    code: string;
    publicNotes?: string;
  };
  requestRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onFailed?: () => void;
};

export const PublicNoteModal = withControlledMount(
  ({
    open,
    isEdit,
    itemData,
    requestRecordId,
    onCancel,
    onCompleted,
    onFailed,
  }: PublicNoteModalProps) => {
    const [noteEditorState, setNoteEditorState] = useState<EditorState>(
      itemData?.publicNotes
        ? setInitialEditorStateFromHTML(itemData?.publicNotes)
        : getEmptyEditorState(),
    );

    const { createUpdatePublicNote, loadingCreatePublicNote } =
      useCreateUpdatePublicNote();

    const handleOnSubmit = async () => {
      const note = isEditorStateBlank(noteEditorState)
        ? ''
        : getEditorHTMLContent(noteEditorState);

      try {
        await createUpdatePublicNote({
          variables: {
            attributeCode: itemData?.code,
            id: requestRecordId,
            publicNotes: note,
          },
        });

        onCompleted?.();
      } catch {
        message.error('Failed to add a note, please try again later.');
        onFailed?.();
      }
    };

    return (
      <Modal
        open={open}
        title={`${isEdit ? 'Edit' : 'Add'} Note`}
        centered
        okText={isEdit ? 'Edit' : 'Add'}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleOnSubmit}
        okButtonProps={{ loading: loadingCreatePublicNote }}
      >
        <FullWidthSpace size="small" direction="vertical">
          <Typography.Text>Note:</Typography.Text>
          <RichTextEditor
            autofocus
            isValid
            minHeight={32}
            editorState={noteEditorState}
            placeholder="Add note description..."
            onChange={(e: EditorState) => setNoteEditorState(e)}
          />
          <Typography.Text type="secondary">
            This note will be visible on the request record page
          </Typography.Text>
        </FullWidthSpace>
      </Modal>
    );
  },
);
