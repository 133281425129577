import useSetState from '@common/hooks/useSetState';
import type {
  ComplianceRequirement,
  ComplianceSubject,
} from '@graphql/types/graphql';
import type { ActionType, ItemType } from '../constants';

type SubjectData = {
  code: ComplianceSubject['code'];
  label: string;
  status: ComplianceSubject['status'];
  notes?: ComplianceSubject['notes'];
  resetOn?: ComplianceSubject['resetOn'];
  publicNote?: ComplianceRequirement['publicNotes'];
};

type RequirementData = {
  code: ComplianceRequirement['attributeCode'];
  label: string;
  publicNote?: ComplianceRequirement['publicNotes'];
  status: ComplianceRequirement['status'];
  notes?: ComplianceRequirement['notes'];
  resetOn?: ComplianceRequirement['resetOn'];
};

type WaiveAndOverrideModalState =
  | {
      visible: false;
      actionType?: ActionType;
      itemType?: ItemType;
      itemData?: SubjectData | RequirementData;
    }
  | {
      visible: true;
      actionType: ActionType;
      itemType: ItemType;
      itemData: SubjectData | RequirementData;
    };

export const useWaiveOverrideModalState = () => {
  const [waiveAndOverrideModalState, setWaiveAndOverrideModalState] =
    useSetState<WaiveAndOverrideModalState>({
      visible: false,
    });

  return {
    waiveAndOverrideModalState,
    setWaiveAndOverrideModalState,
  };
};
