import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import type { CreateRequestRecordsInput } from '@graphql/types/graphql';

const CREATE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation CreateRequestRecords($data: CreateRequestRecordsInput!) {
    createRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useCreateRequestRecordsMutation = () => {
  const [createRequestRecordsMutation, { loading }] = useMutation(
    CREATE_REQUEST_RECORDS_MUTATION,
  );

  const createRequestRecords = async ({
    requestRecords,
    recordType,
    recordId,
  }: CreateRequestRecordsInput & {
    recordType: 'contextRecordId' | 'primaryRecordId';
    recordId: string;
  }) => {
    return await createRequestRecordsMutation({
      variables: {
        data: {
          requestRecords: requestRecords.map((request) => ({
            ...request,
            [recordType]: recordId,
          })),
        },
      },
    });
  };

  return {
    createRequestRecords,
    loading,
  };
};
