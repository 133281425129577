import { SendOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { forwardRef, useMemo } from 'react';

import {
  CellActions,
  type CustomSelectionChangedEvent,
  DateCustomFilter,
  type FilterChangedParams,
  LinkCell,
  Table,
  type TableRef,
  type TableSortingState,
  type TableState,
} from '@common/components/Table';
import {
  COMMON_CELL_CLASSES,
  NUMBER_FILTER_OPTION_TYPES,
} from '@common/components/Table/constants';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { ComplianceStats } from '@common/components/ComplianceStats';
import type { PrimaryRecord } from '@graphql/types/graphql';
import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import { DocumentExpirationDate } from '@modules/document/containers/DocumentsList/components/DocumentExpirationDate/DocumentExpirationDate';
import {
  type ActionType,
  PRIMARY_RECORDS_ACTION_TYPES,
} from '@modules/primary-records';
import { PrimaryRecordStatus } from '@trustlayer/common';
import { Link } from 'react-router';
import styled from 'styled-components';
import { ActiveDocuments } from './components/ActiveDocuments';
import { CompliancePopoverContent } from './components/CompliancePopoverContent';
import { Tags } from './components/Tags';
import { TotalEmails } from './components/TotalEmails';
import {
  COLUMN_FIELDS,
  CONTACT_EMAIL_STATUS,
  OVERRIDDEN_STATUS,
  PRIMARY_RECORD_STATUS,
  REVIEW_STATUS,
  SEVERITY_STATUS,
  WAIVERS_STATUS,
} from './constants';
import { ContextRecordsFilter } from './containers/ContextRecordFilter';
import { PrimaryRecordTypesFilter } from './containers/PrimaryRecordTypesFilter';
import { TagsFilter } from './containers/TagsFilter';
import { usePrimaryRecords } from './hooks/usePrimaryRecords';

export type PrimaryRecordsTableRef = TableRef;

type RecordsTableProps = {
  primaryObjectSlug: string;
  organizationSlugifyName: string;
  onSelectionChanged: (params: CustomSelectionChangedEvent) => void;
  onFilterChanged: (params: FilterChangedParams) => void;
  onSortChanged: (params: TableSortingState) => void;
  onPaginationChanged: (params: any) => void;
  onRowActionClick: (
    actionType: ActionType,
    record: PrimaryRecord,
    options?: {
      totalDocumentsCount?: number;
    },
  ) => void;
  tableState?: TableState;
};

type SetFilterValue = {
  code: string;
  name: string;
};

const commonSetFilterParams = {
  keyCreator: ({ value }: { value: SetFilterValue }) => value.code,
  valueFormatter: ({ value }: { value: SetFilterValue }) => value.name,
};

export const PrimaryRecordsTable = forwardRef<
  PrimaryRecordsTableRef,
  RecordsTableProps
>(
  (
    {
      primaryObjectSlug,
      organizationSlugifyName,
      onSelectionChanged,
      onFilterChanged,
      onSortChanged,
      onPaginationChanged,
      onRowActionClick,
      tableState = { filters: {}, sorting: {}, pagination: {} },
    },
    recordsTableRef,
  ) => {
    const { isComplianceRequirementsAddOnEnabled } =
      useIsComplianceRequirementsAddOnEnabled();
    const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
    const { getPrimaryRecords } = usePrimaryRecords({ primaryObjectSlug });

    const getActionType = (status: PrimaryRecordStatus) => {
      if (status === 'archived') {
        return PRIMARY_RECORDS_ACTION_TYPES.makeActive;
      }

      return PRIMARY_RECORDS_ACTION_TYPES.archive;
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
    const columns = useMemo(
      () => [
        {
          headerName: 'Name',
          field: COLUMN_FIELDS.name,
          width: 400,
          sortable: true,
          pinned: 'left' as const,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          lockPosition: 'left' as const,
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
          },
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          cellRenderer: ({ value: record }: { value: PrimaryRecord }) => {
            const route = `${record._id}/requests`;
            return record?._id && <LinkCell to={route}>{record.name}</LinkCell>;
          },
        },
        {
          headerName: 'Status',
          field: COLUMN_FIELDS.status,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: PRIMARY_RECORD_STATUS.Active,
                name: 'Active',
              },
              {
                code: PRIMARY_RECORD_STATUS.Archived,
                name: 'Archived',
              },
            ],
          },
        },
        {
          headerName: 'Tags',
          field: COLUMN_FIELDS.tags,
          minWidth: 150,
          filter: TagsFilter,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data?.tags,
          cellRenderer: ({ value }: { value: PrimaryRecord['tags'] }) => {
            return <Tags tags={value.nodes} />;
          },
        },
        {
          headerName: 'Status',
          field: COLUMN_FIELDS.complianceStatus,
          hide: !isComplianceRequirementsAddOnEnabled,
          sortable: true,
          minWidth: 150,
          maxWidth: 200,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          cellRenderer: ComplianceCountCell,
        },
        {
          headerName: 'Total Emails',
          field: COLUMN_FIELDS.totalEmails,
          minWidth: 150,
          maxWidth: 200,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => ({
            ...data?.stats.messages,
            recordId: data._id,
          }),
          cellRenderer: ({
            value,
          }: {
            value: PrimaryRecord['stats']['messages'] & {
              recordId: PrimaryRecord['_id'];
            };
          }) => {
            const { totalCount, totalIssuesCount, recordId } = value;
            return (
              <Link to={`${recordId}/messages`}>
                <TotalEmails
                  hasWarnings={totalIssuesCount > 0}
                  value={totalCount}
                />
              </Link>
            );
          },
        },
        {
          headerName: 'Active Documents',
          field: COLUMN_FIELDS.activeDocuments,
          minWidth: 150,
          maxWidth: 200,
          sortable: false,
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: [
              NUMBER_FILTER_OPTION_TYPES.equals,
              NUMBER_FILTER_OPTION_TYPES.greaterThan,
              NUMBER_FILTER_OPTION_TYPES.lessThan,
            ],
          },
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          cellRenderer: ({ value }: { value: PrimaryRecord }) => {
            const { unreviewedCount = 0, totalCount = 0 } =
              value?.stats?.documents || {};

            const BASE_PATH_DOCUMENT_REVIEW_PAGE = `/${organizationSlugifyName}/p/${primaryObjectSlug}/${value._id}/documents-review`;

            return (
              <ActiveDocuments
                totalDocuments={totalCount}
                activeDocuments={unreviewedCount}
                redirectBasePath={BASE_PATH_DOCUMENT_REVIEW_PAGE}
              />
            );
          },
        },
        {
          headerName: 'Review Status',
          field: COLUMN_FIELDS.reviewStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              { code: REVIEW_STATUS.Reviewed, name: 'Reviewed' },
              { code: REVIEW_STATUS.ToReview, name: 'To Review' },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Flags',
          field: COLUMN_FIELDS.flagsSeverity,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              { name: 'High severity', code: SEVERITY_STATUS.High },
              { name: 'Medium severity', code: SEVERITY_STATUS.Medium },
              { name: 'Low severity', code: SEVERITY_STATUS.Low },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Types',
          field: COLUMN_FIELDS.types,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: PrimaryRecordTypesFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Context Records',
          field: COLUMN_FIELDS.contextRecords,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: isContextsAddOnEnabled ? ContextRecordsFilter : undefined,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        //! Removed this filter until we have a clear path on how to implement it
        // {
        //   headerName: 'Automations',
        //   field: COLUMN_FIELDS.automations,
        //   cellDataType: 'text',
        //   sortable: false,
        //   hide: true,
        //   filter: AutomationsFilter,
        //   valueGetter: ({ data }: { data: any }) => data.record?.name,
        // },
        {
          headerName: 'Waivers Status',
          field: COLUMN_FIELDS.waiversStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: isComplianceRequirementsAddOnEnabled
            ? 'agSetColumnFilter'
            : false,
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: WAIVERS_STATUS.WithRequirements,
                name: 'With Waived Requirements',
              },
              {
                code: WAIVERS_STATUS.WithoutRequirements,
                name: 'Without Waived Requirements',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Overrides Status',
          field: COLUMN_FIELDS.overridesStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: isComplianceRequirementsAddOnEnabled
            ? 'agSetColumnFilter'
            : false,
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: OVERRIDDEN_STATUS.WithOverriddenRequirements,
                name: 'With Overridden Requirements',
              },
              {
                code: OVERRIDDEN_STATUS.WithoutOverriddenRequirements,
                name: 'Without Overridden Requirements',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Next Expiration Date',
          field: COLUMN_FIELDS.nextComplianceExpiration,
          sortable: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) =>
            data.nextComplianceExpiration,
          cellRenderer: ({ value }: { value: string }) => {
            return <DocumentExpirationDate expirationDate={value} />;
          },
        },
        {
          headerName: 'Last Activity Date',
          field: COLUMN_FIELDS.lastActivity,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Message Failures Date',
          field: COLUMN_FIELDS.messageFailures,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Contact Email Status',
          field: COLUMN_FIELDS.contactEmailStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: CONTACT_EMAIL_STATUS.WithContactEmail,
                name: 'With Contact Email',
              },
              {
                code: CONTACT_EMAIL_STATUS.WithoutContactEmail,
                name: 'Without Contact Email',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Added',
          field: COLUMN_FIELDS.createdAt,
          sortable: true,
          hide: true,
        },
        {
          headerName: '',
          field: COLUMN_FIELDS.actions,
          lockPosition: 'right' as const,
          pinned: 'right' as const,
          width: 90,
          maxWidth: 100,
          sortable: false,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          //eslint-disable-next-line
          cellRenderer: ({ value: data }: { value: PrimaryRecord }) => (
            <CellActions>
              <CellActions.Button
                icon={<SendOutlined />}
                onClick={() => {
                  onRowActionClick(
                    PRIMARY_RECORDS_ACTION_TYPES.sendRequest,
                    data,
                  );
                }}
              />
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label: 'Enable Automation',
                      code: PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
                          data,
                        );
                      },
                    },
                    {
                      label: 'Disable Automation',
                      code: 'disableAutomation',
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.disableAutomation,
                          data,
                        );
                      },
                    },
                    {
                      label:
                        data?.status === PrimaryRecordStatus.Archived
                          ? 'Make Active'
                          : 'Archive',
                      code: getActionType(data?.status),
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(getActionType(data?.status), data);
                      },
                    },
                    {
                      label: 'Remove Tags',
                      code: PRIMARY_RECORDS_ACTION_TYPES.removeTags,
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.removeTags,
                          data,
                        );
                      },
                    },
                    {
                      label: 'Add Tags',
                      code: PRIMARY_RECORDS_ACTION_TYPES.addTags,
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.addTags,
                          data,
                        );
                      },
                    },
                    {
                      label: 'Delete',
                      code: 'delete',
                      disabled: false,
                      alert: true,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.delete,
                          data,
                        );
                      },
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          ),
        },
      ],
      //eslint-disable-next-line
      [primaryObjectSlug],
    );

    return (
      <Table
        ref={recordsTableRef}
        columnDefs={columns}
        getRowData={getPrimaryRecords}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onPaginationChanged={onPaginationChanged}
        tableState={tableState}
      />
    );
  },
);

const ComplianceCountCell = ({
  value,
}: { value: Pick<PrimaryRecord, 'stats' | '_id' | 'name'> }) => {
  const { compliantCount, totalCount } = value.stats.requestRecords;

  return (
    <Popover content={<CompliancePopoverContent primaryRecordId={value._id} />}>
      <StyledRecordsComplianceWrapper>
        <ComplianceStats
          compliantCount={compliantCount}
          totalCount={totalCount}
        />
      </StyledRecordsComplianceWrapper>
    </Popover>
  );
};

const StyledRecordsComplianceWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;
