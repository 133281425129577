import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { trackEvent } from '@common/utils/track-helpers';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { AddTagsForm } from '@modules/primary-records/components/AddTagsForm';
import { Modal, Typography, message } from 'antd';
import { useState } from 'react';
import { useAddTagsToPrimaryRecords } from '../../hooks/useAddTagsToPrimaryRecords';

type Tag = {
  _id?: string;
  name: string;
};

type AddTagsToPrimaryRecordsModalProps = {
  open: boolean;
  count: number;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  filters?: ServerFilterInput;
  onCompleted?: () => void;
  onCancel: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const getLabelByCount = (
  count: number,
  primaryObject: { name: string; pluralName: string },
) => (count > 1 ? primaryObject.pluralName : primaryObject.name).toLowerCase();

export const AddTagsToPrimaryRecordsModal = ({
  open,
  count,
  primaryObject,
  filters,
  onCompleted,
  onCancel,
  onScheduled,
  onFailed,
}: AddTagsToPrimaryRecordsModalProps) => {
  const [selectedPartyTags, setSelectedPartyTags] = useState<Tag[]>([]);

  const onTagSelect = (tag: Tag) => {
    setSelectedPartyTags([...selectedPartyTags, tag]);
  };

  const { addTagsToPrimaryRecords, loading: addingTags } =
    useAddTagsToPrimaryRecords();

  const onConfirm = async () => {
    const res = await addTagsToPrimaryRecords(selectedPartyTags, filters);
    const status = res.data?.addTagsToPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `Tags have been assigned to ${count} ${getLabelByCount(count, primaryObject)} successfully.`,
      );
      trackEvent('User added tags to parties');
      setSelectedPartyTags([]);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `Tags assignment has been scheduled for ${count} ${getLabelByCount(count, primaryObject)}`,
      );
      trackEvent('User added tags to parties');
      setSelectedPartyTags([]);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The tags assignment for your ${getLabelByCount(count, primaryObject)} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        setSelectedPartyTags([]);
        onCancel();
      }}
      onOk={onConfirm}
      okButtonProps={{ loading: addingTags }}
    >
      <>
        <Typography.Paragraph>
          {`Select which tags you want to add to ${getLabelByCount(count, primaryObject)}`}
        </Typography.Paragraph>
        <AddTagsForm
          tags={selectedPartyTags}
          onSelect={onTagSelect}
          onRemove={(tag) => {
            setSelectedPartyTags(
              selectedPartyTags.filter(({ name }) => name !== tag.name),
            );
          }}
        />
      </>
    </Modal>
  );
};
