import {
  ApolloClient,
  ApolloLink,
  type DefaultOptions,
  type DocumentNode,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { getMainDefinition } from '@apollo/client/utilities';

import AppConfig from '@config/AppConfig';

import errorLink from './links/errorLink';
import flattenPdfLink from './links/flattenPdfLink';
import prepareRequestLink from './links/prepareRequestLink';
import tokenRefreshLink from './links/tokenRefreshLink';
import uploadLink from './links/uploadLink';
import wsLink from './links/wsLink';
import {
  objectsMerge,
  offsetLimitPagination,
  offsetPagination,
  paginationWithInput,
  skipLimitPagination,
} from './policies';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
};

const typePolicies = {
  Query: {
    fields: {
      notifications: offsetPagination(),
      tags: paginationWithInput(),
      listEventLogs: skipLimitPagination(['filters', ['partyId']]),
      complianceProfiles: paginationWithInput(),
      listSubjects: paginationWithInput(),
      listOrganizations: paginationWithInput(),
      contacts: paginationWithInput(),
      attributes: offsetLimitPagination(),
      contextRecords: paginationWithInput(),
    },
  },
  PrimaryRecord: {
    fields: {
      emailActivities: offsetLimitPagination(),
      activities: offsetLimitPagination(),
      conversations: offsetLimitPagination(),
      stats: objectsMerge(),
      contacts: { merge: true },
    },
  },
  PrimaryRecordPortal: {
    fields: {
      attachments: offsetLimitPagination(),
    },
  },
  User: {
    fields: {
      // Reference: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      profile: objectsMerge(),
    },
  },
  ComplianceModule: {
    keyFields: ['code'],
  },
  ComplianceSubject: {
    keyFields: ['code'],
  },
  ComplianceRequirement: {
    keyFields: ['attributeCode'],
  },
  // TODO - remove once mocked documentChecklists is removed
  DocumentNew: {
    fields: {
      documentChecklists: objectsMerge(),
      insurers: objectsMerge(),
    },
  },
};

const customUploadLink = ApolloLink.from([flattenPdfLink, uploadLink]);
const removeTypenameLink = removeTypenameFromVariables();

const networkLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  customUploadLink,
  new HttpLink({
    uri: AppConfig.graphqlApiUrl,
  }),
);

const isSubscriptionOperation = ({ query }: { query: DocumentNode }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    removeTypenameLink,
    tokenRefreshLink,
    errorLink,
    prepareRequestLink,
    split(isSubscriptionOperation, wsLink, networkLink),
  ]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions,
});

export const cypressComponentTestingClient = new ApolloClient({
  link: ApolloLink.from([
    removeTypenameLink,
    errorLink,
    prepareRequestLink,
    split(isSubscriptionOperation, wsLink, networkLink),
  ]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions,
});

export default client;
