import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION = graphql(`
  mutation deleteRequestRecordRequirementPublicNotes($data: [UpdateRequestRecordRequirementsPublicNotesInput!]!) {
    updateRequestRecordRequirementsPublicNotes(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useDeletePublicNoteMutation = () => {
  const [updatePublicNote, { error, loading }] = useMutation(
    DELETE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION,
  );

  const deletePublicNote = async ({
    variables: { attributeCode, id },
  }: {
    variables: {
      attributeCode: string;
      id: string;
    };
  }) => {
    return await updatePublicNote({
      variables: {
        data: [
          {
            attributeCode,
            id,
            publicNotes: null,
          },
        ],
      },
    });
  };

  return {
    deletePublicNote,
    isDeletingPublicNote: loading,
    deletePuiblicNoteError: error,
  };
};
