import { STATUS_TYPES } from '../../constants';
import {
  NOTE_REQUIREMENT_ACTIONS,
  REQUIREMENT_ACTIONS_BY_STATUS,
} from './RequirementActionsMenu.constants';

const addDisabledField = (items: any[]) =>
  items.map((item) => ({ ...item, disabled: true }));

export const getRequirementActions = ({
  status,
  inherited,
  hasNote,
}: { status: STATUS_TYPES; inherited: boolean; hasNote: boolean }) => {
  const requirementStatus =
    status in STATUS_TYPES ? status : STATUS_TYPES.default;
  const dynamicActions = inherited
    ? addDisabledField(REQUIREMENT_ACTIONS_BY_STATUS[requirementStatus])
    : REQUIREMENT_ACTIONS_BY_STATUS[requirementStatus];

  const [ADD_NOTE_ACTION, EDIT_NOTE_ACTION] = NOTE_REQUIREMENT_ACTIONS;

  return [
    ...dynamicActions,
    ...(!hasNote ? [ADD_NOTE_ACTION] : [EDIT_NOTE_ACTION]),
  ];
};
