import { Button, Card, Skeleton } from 'antd';
import styled from 'styled-components';

import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ThreeDotsMenu } from '@trustlayer/ui';
import type { PropsWithChildren } from 'react';
import { ComplianceSummaryContent } from './ComplianceSummaryContent';

export enum ActionKeys {
  makeCompliant = 'makeCompliant',
  edit = 'edit',
  assign = 'assign',
  unassign = 'unassign',
}

type ComplianceSummaryProps = PropsWithChildren<{
  name?: string;
  hasProfile: boolean;
  hideActions?: boolean;
  loading?: boolean;
  isCustomComplianceProfile?: boolean;
  compliantSubjectsCount?: number;
  totalSubjectsCount?: number;
  onActionClick: (key: ActionKeys) => void;
}>;

const DEFAULT_NAME = 'Custom Compliance Profile';
const ACTIONS = [
  {
    label: 'Make Compliant',
    code: ActionKeys.makeCompliant,
  },
  {
    label: 'Unassign compliance profile',
    code: ActionKeys.unassign,
  },
];

const EmptyStateContent = ({
  onAssignClick,
}: { onAssignClick: () => void }) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const elementLabel = isComplianceRequirementsAddOnEnabled
    ? 'profile'
    : 'checklist';

  const title = isComplianceRequirementsAddOnEnabled
    ? 'Assign a compliance profile to track requirements and expirations.'
    : 'Assign a checklist to track documents.';

  return (
    <section>
      <StyledSecondaryTitle>{title}</StyledSecondaryTitle>
      <Button onClick={onAssignClick}>Assign {elementLabel}</Button>
    </section>
  );
};

export const ComplianceSummary = ({
  name = DEFAULT_NAME,
  loading = false,
  hideActions,
  onActionClick,
  hasProfile,
  isCustomComplianceProfile,
  compliantSubjectsCount,
  totalSubjectsCount,
}: ComplianceSummaryProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const content = hasProfile ? (
    <ComplianceSummaryContent
      profileName={name}
      isCustomComplianceProfile={isCustomComplianceProfile}
      hideEditAction={hideActions}
      compliantSubjectsCount={compliantSubjectsCount}
      totalSubjectsCount={totalSubjectsCount}
      onEditClick={() => {
        onActionClick(ActionKeys.edit);
      }}
    />
  ) : (
    <EmptyStateContent
      onAssignClick={() => {
        onActionClick(ActionKeys.assign);
      }}
    />
  );

  return (
    <Card
      bordered
      title={
        isComplianceRequirementsAddOnEnabled
          ? 'Compliance summary'
          : 'Checklist'
      }
      extra={
        hideActions || !isComplianceRequirementsAddOnEnabled ? undefined : (
          <ThreeDotsMenu<ActionKeys>
            testKey={'compliance-summary-actions-menu'}
            items={ACTIONS}
            onClick={(key) => {
              onActionClick(key);
            }}
          />
        )
      }
    >
      {loading ? <StyledSkeleton active /> : content}
    </Card>
  );
};

const StyledSkeleton = styled(Skeleton.Input)`
  width: 100% !important;

  &&.ant-skeleton-input {
    width: 100%;
  }
`;

const StyledSecondaryTitle = styled.p`
  margin: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.subtitleText}
`;
