import { DiffOutlined } from '@ant-design/icons';
import {
  type RequirementAttributeType,
  type RequirementOperator,
  parseCurrency,
} from '@trustlayer/common';
import { Tooltip, Typography } from 'antd';
import { htmlToText } from 'html-to-text';
import styled from 'styled-components';
import {
  AttributeType,
  Operator,
  financialStrengthValues,
  ratingValues,
} from '../constants';
import {
  getOperatorDisplayValue,
  getRuleLabelForCompletedByOperator,
} from '../utils/compliance-attributes-helpers';

const { Paragraph } = Typography;

const MatchedRepeatedly = () => (
  <Tooltip title="Must be matched repeatedly">
    <DiffOutlined />
  </Tooltip>
);

export const RuleDisplayValue = ({
  moduleCode,
  rule,
}: {
  moduleCode: string;
  rule: {
    attributeLabel: string;
    attributeType: RequirementAttributeType;
    matchingCriteria: string;
    operator: RequirementOperator;
    targetValue?: string;
    documentAttributeCode: string;
    custom: boolean;
  };
}) => {
  const {
    attributeLabel,
    attributeType,
    matchingCriteria,
    operator,
    targetValue,
    documentAttributeCode,
    custom,
  } = rule;

  return (
    <Tooltip title={documentAttributeCode} trigger={'contextMenu'}>
      {custom ? (
        <StyledCustomRequirementLabel className="attribute-label">
          {matchingCriteria === 'matchRepeatedly' && <MatchedRepeatedly />}{' '}
          {htmlToText(attributeLabel)}{' '}
          {(operator === Operator.GREATER_OR_EQUAL ||
            operator === Operator.LESS_OR_EQUAL) && (
            <>
              <span className="operator">{operator}</span>{' '}
              <Highlight className="target-value">
                {parseCurrency(targetValue || '')}
              </Highlight>
            </>
          )}
        </StyledCustomRequirementLabel>
      ) : (
        <span>
          {matchingCriteria === 'matchRepeatedly' && <MatchedRepeatedly />}{' '}
          <Highlight className="attribute-label">{attributeLabel}</Highlight>{' '}
          <span className="operator">
            {getOperatorDisplayValue({
              moduleCode,
              attributeType,
              operator,
            })}
          </span>{' '}
          {attributeType === AttributeType.Number &&
            (operator === Operator.GREATER_OR_EQUAL ||
              operator === Operator.LESS_OR_EQUAL) && (
              <Highlight className="target-value">
                {parseCurrency(targetValue || '')}
              </Highlight>
            )}
          {attributeType === AttributeType.AmBestRating && (
            <Highlight className="target-value">
              {
                ratingValues[
                  targetValue as unknown as keyof typeof ratingValues
                ]
              }
            </Highlight>
          )}
          {attributeType === AttributeType.AmBestFinancialStrength && (
            <Highlight className="target-value">
              {
                financialStrengthValues[
                  targetValue as unknown as keyof typeof financialStrengthValues
                ]
              }
            </Highlight>
          )}
          {operator === Operator.COMPLETED_BY && (
            <Highlight className="target-value">
              {getRuleLabelForCompletedByOperator({
                targetValue: targetValue || '',
              })}
            </Highlight>
          )}
        </span>
      )}
    </Tooltip>
  );
};

const Highlight = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

const StyledCustomRequirementLabel = styled(Paragraph)`
  font-weight: normal;
  color: ${(props) => props.theme.colors.grayishBlue};

  &.ant-typography {
    margin-bottom: 0 !important;
  }
`;

export const CustomFieldTitle = styled.span`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 7px;
`;
