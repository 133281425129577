import { FILTER_OPERATORS } from '@common/constants/filters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  ContextRecordsQuery,
  ContextRecordsQueryVariables,
} from '@graphql/types/graphql';
import { ContextRecordStatus } from '@trustlayer/common';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_CONTEXT } from '../constants';

const DEFAULT_PAGE_SIZE = 10;

const ACTIVE_RECORDS_FILTER = {
  name: 'status',
  operator: FILTER_OPERATORS.in,
  value: [ContextRecordStatus.Active],
};

const CONTEXT_RECORDS_QUERY = graphql(`
  query ContextRecords($input: ContextRecordsInput) {
    contextRecords(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

export type UseContextRecordsProps = {
  skip?: boolean;
};

export const useContextRecords = ({
  skip = false,
}: UseContextRecordsProps = {}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [totalAvailableRecords, setTotalAvailableRecords] = useState(0);

  const { data, loading, fetchMore } = useQuery<
    ContextRecordsQuery,
    ContextRecordsQueryVariables
  >(CONTEXT_RECORDS_QUERY, {
    variables: {
      input: {
        first: DEFAULT_PAGE_SIZE,
        offset: 0,
        filter: {
          and: [
            ACTIVE_RECORDS_FILTER,
            ...(searchTerm
              ? [
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: searchTerm,
                  },
                ]
              : []),
          ],
        },
      },
    },
    skip,
  });

  const records = data?.contextRecords.nodes || [];
  const recordsTotalCount = data?.contextRecords.totalCount || 0;

  // Track the total available records (without search filters)
  useEffect(() => {
    if (!searchTerm && recordsTotalCount > 0) {
      setTotalAvailableRecords(recordsTotalCount);
    }
  }, [searchTerm, recordsTotalCount]);

  const filterRecordsByName = useMemo(
    () =>
      debounce((name: string) => {
        setSearchTerm(name);
      }, 500),
    [],
  );

  const fetchMoreRecords = useCallback(() => {
    fetchMore({
      variables: {
        input: {
          first: DEFAULT_PAGE_SIZE,
          offset: records.length,
          filter: {
            and: [
              ACTIVE_RECORDS_FILTER,
              ...(searchTerm
                ? [
                    {
                      name: 'name',
                      operator: FILTER_OPERATORS.contains,
                      value: searchTerm,
                    },
                  ]
                : []),
            ],
          },
        },
      },
    });
  }, [fetchMore, records.length, searchTerm]);

  return {
    records: [DEFAULT_CONTEXT, ...records],
    recordsTotalCount,
    loading,
    filterRecordsByName,
    fetchMoreRecords,
    totalAvailableRecords,
  };
};
