import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import {
  setCurrentComplianceProfile,
  setCurrentDocumentChecklists,
  setCurrentRequirements,
} from '@modules/compliance-profile/actions';
import { DocumentCheckListFormDrawer } from '@modules/document-checklist';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ComplianceProfileContainer from './ComplianceProfileContainer';
import { useComplianceProfileQuery } from './ComplianceProfilesTable/hooks';

type ComplianceProfileEditorWrapperProps = {
  complianceProfileId?: string;
  contextRecordId?: string;
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ChecklistsEditorWrapper = ({
  visible,
  onClose,
  onSuccess,
  complianceProfileId,
}: ComplianceProfileEditorWrapperProps) => {
  return (
    <DocumentCheckListFormDrawer
      visible={visible}
      onClose={onClose}
      onSuccess={onSuccess}
      checklistId={complianceProfileId}
    />
  );
};

const ComplianceProfileContainerWrapper = ({
  visible,
  complianceProfileId,
  contextRecordId,
  onClose,
}: ComplianceProfileEditorWrapperProps) => {
  const { fetchComplianceProfile } = useComplianceProfileQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!complianceProfileId) return;

    fetchComplianceProfile({
      variables: {
        id: complianceProfileId,
      },
      onError(error) {
        console.error(error);
      },
      onCompleted({ getComplianceProfile }) {
        dispatch(setCurrentComplianceProfile(getComplianceProfile));
        dispatch(setCurrentRequirements(getComplianceProfile?.rules || []));
        dispatch(
          setCurrentDocumentChecklists(
            getComplianceProfile?.documentChecklists || [],
          ),
        );
      },
    });
  }, [complianceProfileId, dispatch, fetchComplianceProfile]);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <ComplianceProfileContainer
      isShow={visible}
      onChange={() => {}}
      setIsShow={handleClose}
      isDrawer
    />
  );
};

export const ComplianceProfileEditorWrapper = (
  props: ComplianceProfileEditorWrapperProps,
) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  if (isComplianceRequirementsAddOnEnabled) {
    return <ComplianceProfileContainerWrapper {...props} />;
  }

  return <ChecklistsEditorWrapper {...props} />;
};
