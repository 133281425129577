import { Modal } from '@common/components/Modal';
import { message } from 'antd';
import { useDeletePublicNoteMutation } from './hooks/useDeletePublicNoteMutation';

export const DeletePublicNoteModal = ({
  open,
  itemData,
  requestRecordId,
  onCancel,
  onCompleted,
  onFailed,
}: {
  open: boolean;
  isEdit?: boolean;
  itemData: {
    code: string;
  };
  requestRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onFailed?: () => void;
}) => {
  const { deletePublicNote, isDeletingPublicNote } =
    useDeletePublicNoteMutation();

  const handleDeleteRequirementPublicNote = async () => {
    try {
      await deletePublicNote({
        variables: {
          attributeCode: itemData?.code,
          id: requestRecordId,
        },
      });
      onCompleted?.();
    } catch (error) {
      message.error('Failed to remove note, please try again later.');
      onFailed?.();
    }
  };

  return (
    <Modal
      open={open}
      title="Delete Public Note"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleDeleteRequirementPublicNote}
      okButtonProps={{ loading: isDeletingPublicNote, danger: true }}
      width={400}
    >
      Are you sure you want to delete this public note?
    </Modal>
  );
};
