export const getRuleLabelForCompletedByOperator = ({
  targetValue,
  primaryRecordName,
  workspaceName,
}: {
  targetValue: string;
  primaryRecordName?: string;
  workspaceName?: string;
}) => {
  return targetValue === 'party'
    ? primaryRecordName || 'Party'
    : workspaceName || 'Requester (Me)';
};
