import { ExclamationCircleFilled } from '@ant-design/icons';
import { withControlledMount } from '@common/hoc/withControlledMount';
import {
  BulkOperationStatus,
  type RemoveRequestRecordRequirementWaiverOverrideMutation,
  type RemoveRequestRecordSubjectWaiverOverrideMutation,
} from '@graphql/types/graphql';
import { Modal, Space, message } from 'antd';
import styled from 'styled-components';
import { ACTION_NOUNS_MAP, ActionType, ItemType } from './constants';
import { useRemoveWaiveOverrideRequirementMutation } from './hooks/useRemoveWaiveOverrideRequirementMutation';
import { useRemoveWaiveOverrideSubjectMutation } from './hooks/useRemoveWaiveOverrideSubjectMutation';

type RemoveWaiveOverrideModalProps = {
  open: boolean;
  actionType: ActionType;
  itemType: ItemType;
  itemData: {
    code: string;
  };
  requestRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const RemoveWaiveOverrideModal = withControlledMount(
  ({
    open,
    actionType,
    itemType,
    itemData,
    requestRecordId,
    onCancel,
    onCompleted,
    onScheduled,
    onFailed,
  }: RemoveWaiveOverrideModalProps) => {
    const {
      loading: loadingRemoveWaiverOverrideRequirement,
      removeWaiverOverrideRequirement,
    } = useRemoveWaiveOverrideRequirementMutation();
    const {
      loading: loadingRemoveWaiverOverrideSubject,
      removeWaiverOverrideSubject,
    } = useRemoveWaiveOverrideSubjectMutation();

    const handleOnMutationCompleted = (
      res:
        | RemoveRequestRecordRequirementWaiverOverrideMutation
        | RemoveRequestRecordSubjectWaiverOverrideMutation,
    ) => {
      const status =
        'toggleRequestRecordRequirementsWaiver' in res
          ? res.toggleRequestRecordRequirementsWaiver?.operation.status
          : res.toggleRequestRecordSubjectsWaiver?.operation.status;

      const itemTypeLabel =
        itemType === ItemType.subject ? 'subject' : 'requirement';
      const actionTypeLabel = ACTION_NOUNS_MAP[actionType];

      if (status === BulkOperationStatus.Completed) {
        message.success(
          `The ${actionTypeLabel} has been successfully removed from the ${itemTypeLabel}.`,
        );
        onCompleted?.();
      }

      if (status === BulkOperationStatus.Scheduled) {
        message.success(
          `The removal of the ${actionTypeLabel} from the ${itemTypeLabel} has been scheduled.`,
        );
        onScheduled?.();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `The removal of the ${actionTypeLabel} from the ${itemTypeLabel} has failed. Please try again or contact support.`,
        );
        onFailed?.();
      }
    };

    const handleRemoveWaiverOverride = () => {
      if (!open || !itemData) return;

      if (itemType === ItemType.subject) {
        removeWaiverOverrideSubject({
          variables: {
            requestId: requestRecordId!,
            code: itemData.code,
          },
          onCompleted: handleOnMutationCompleted,
        });
      }

      if (itemType === ItemType.requirement) {
        removeWaiverOverrideRequirement({
          variables: {
            requestId: requestRecordId!,
            code: itemData.code,
          },
          onCompleted: handleOnMutationCompleted,
        });
      }
    };

    return (
      <Modal
        width={384}
        closable={false}
        title={
          <Space size="middle">
            <StyledExclamationCircleFilled />
            <StyledRemoveWaiverOverrideModalContent>{`Are you sure you want to remove the ${actionType === ActionType.waive ? 'waiver' : 'override'} from this ${itemType === ItemType.subject ? 'subject' : 'requirement'}?`}</StyledRemoveWaiverOverrideModalContent>
          </Space>
        }
        open={open}
        onCancel={onCancel}
        onOk={handleRemoveWaiverOverride}
        confirmLoading={
          loadingRemoveWaiverOverrideSubject ||
          loadingRemoveWaiverOverrideRequirement
        }
      />
    );
  },
);

// Replicating Modal.warning
const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
  font-size: 22px;
  color: #faad14;
`;

const StyledRemoveWaiverOverrideModalContent = styled.div`
  font-size: 16px;
`;
