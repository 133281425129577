import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const COMPLIANCE_PROFILE_CONTAINER_SELECTED_VALUE = graphql(`
  query ComplianceProfilesTable_ComplianceProfileQuery($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      context
      contextId
      baseComplianceProfile
      rules {
        _id
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        operator
        targetValue
        isActive
        complianceProfile
        invalidatedBy
        invalidationScope
      }
      documentChecklists {
        _id
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
      partiesCount
      projectsCount
      createdAt
      updatedAt
      deletedAt
      deletedBy
    }
  }
`);

export const useComplianceProfileQuery = () => {
  const [fetchComplianceProfile, { data, loading }] = useLazyQuery(
    COMPLIANCE_PROFILE_CONTAINER_SELECTED_VALUE,
  );

  return {
    fetchComplianceProfile,
    complianceProfile: data?.getComplianceProfile,
    isComplianceProfileLoading: loading,
  };
};
