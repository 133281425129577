import { useTableControl } from '@common/components/Table';
import { ComplianceProfilesTable } from '@modules/context-records/containers/ComplianceProfile';
import type { ContextRecordRoutesParams } from '@modules/router/types';
import { useParams } from 'react-router';

export function ContextRecordComplianceProfiles() {
  const tableControl = useTableControl();
  const { recordId } = useParams<ContextRecordRoutesParams>();

  return (
    <ComplianceProfilesTable
      contextRecordId={recordId!}
      tableControl={tableControl}
    />
  );
}
