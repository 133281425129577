import { Progress } from 'antd';
import styled from 'styled-components';

type CircleProgressProps = {
  currentActiveItemsCount: number;
  totalItemsCount: number;
  strokeWidth?: number;
  width?: number;
  fontSize?: number;
};

const DEFAULT_PROGRESS_CONFIG = {
  fontSize: 18,
  width: 80,
  strokeWidth: 5,
};

export const CircleProgress = ({
  currentActiveItemsCount,
  totalItemsCount,
  strokeWidth = DEFAULT_PROGRESS_CONFIG.strokeWidth,
  width = DEFAULT_PROGRESS_CONFIG.width,
  fontSize = DEFAULT_PROGRESS_CONFIG.fontSize,
  ...rest
}: CircleProgressProps) => {
  const isSuccess = currentActiveItemsCount === totalItemsCount;

  return (
    <Progress
      {...rest}
      format={() => (
        <StyledProgressFormat
          fontSize={fontSize}
          data-cy="partyComplianceIndicatorText"
        >{`${currentActiveItemsCount}/${totalItemsCount}`}</StyledProgressFormat>
      )}
      percent={(currentActiveItemsCount / totalItemsCount) * 100}
      size="small"
      type="circle"
      strokeWidth={strokeWidth}
      width={width}
      status={isSuccess ? 'success' : 'active'}
    />
  );
};

const StyledProgressFormat = styled.div<{ fontSize: number }>`
  font-size: ${(props) => props.fontSize}px;
`;
