import { FILTER_OPERATORS } from '@common/constants/filters/serverFilters';
import { trackEvent } from '@common/utils/track-helpers';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { AddTagsForm } from '@modules/primary-records/components/AddTagsForm';
import { Modal, Skeleton, Typography, message } from 'antd';
import { useState } from 'react';
import { useAddTagsToPrimaryRecords } from '../../hooks/useAddTagsToPrimaryRecords';
import { usePrimaryRecordQuery } from './hooks/usePrimaryRecordQuery';

type Tag = {
  _id?: string;
  name: string;
};

type AddTagsToPrimaryRecordsModalProps = {
  open: boolean;
  primaryRecordId: string;
  onCompleted?: () => void;
  onCancel: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const AddTagsToPrimaryRecordModal = ({
  open,
  primaryRecordId,
  onCompleted,
  onCancel,
  onScheduled,
  onFailed,
}: AddTagsToPrimaryRecordsModalProps) => {
  const [selectedPartyTags, setSelectedPartyTags] = useState<Tag[]>([]);

  const { primaryRecord, loading } = usePrimaryRecordQuery({
    id: primaryRecordId,
    skip: !open || !primaryRecordId,
  });

  const onTagSelect = (tag: Tag) => {
    setSelectedPartyTags([...selectedPartyTags, tag]);
  };

  const { addTagsToPrimaryRecords, loading: addingTags } =
    useAddTagsToPrimaryRecords();

  const onConfirm = async () => {
    const res = await addTagsToPrimaryRecords(selectedPartyTags, {
      and: [
        {
          name: 'id',
          operator: FILTER_OPERATORS.equal,
          value: primaryRecordId,
        },
      ],
    });
    const status = res.data?.addTagsToPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success('Tags have been assigned successfully.');
      trackEvent('User added tags to parties');
      setSelectedPartyTags([]);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success('Tags have been scheduled for assignment.');
      trackEvent('User added tags to parties');
      setSelectedPartyTags([]);
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        'The tags assignment failed. Please try again or contact support.',
      );
      onFailed?.();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onConfirm}
      okButtonProps={{ loading: addingTags }}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Typography.Paragraph>
            {`Select which tags you want to add to ${primaryRecord?.name}`}
          </Typography.Paragraph>
          <AddTagsForm
            tags={selectedPartyTags}
            onSelect={onTagSelect}
            onRemove={(tag) => {
              setSelectedPartyTags(
                selectedPartyTags.filter(({ name }) => name !== tag.name),
              );
            }}
          />
        </>
      )}
    </Modal>
  );
};
