import useSetState from '@common/hooks/useSetState';

type DeletePublicNoteModalState = {
  visible: boolean;
  itemData?: {
    code: string;
  };
};

export const useDeletePublicNoteModalState = () => {
  const [deletePublicNoteModalState, setDeletePublicNoteModalState] =
    useSetState<DeletePublicNoteModalState>({
      visible: false,
    });

  return {
    deletePublicNoteModalState,
    setDeletePublicNoteModalState,
  };
};
