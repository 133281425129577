import { useSearchParams } from 'react-router';

interface UseDrawerStateOptions<T> {
  paramName: string;
  defaultValue?: T;
}

export const useDrawerState = <T extends string>({
  paramName,
  defaultValue,
}: UseDrawerStateOptions<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(paramName) as T | null;

  const openDrawer = (newValue: T) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set(paramName, newValue);
      return params;
    });
  };

  const closeDrawer = () => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.delete(paramName);
      return params;
    });
  };

  return {
    value: value ?? defaultValue ?? null,
    isOpen: !!value,
    openDrawer,
    closeDrawer,
  };
};
