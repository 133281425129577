import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PRIMARY_RECORD_TYPE_QUERY = graphql(`
  query PrimaryRecordType($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      type {
        defaultComplianceProfile {
          _id
        }
      }
    }
  }
`);

type UsePrimaryRecordTypeProps = {
  primaryRecordId: string;
  skip?: boolean;
};

export const usePrimaryRecordType = ({
  primaryRecordId,
  skip = false,
}: UsePrimaryRecordTypeProps) => {
  const { data, loading, error } = useQuery(PRIMARY_RECORD_TYPE_QUERY, {
    variables: {
      primaryRecordInput: { id: primaryRecordId },
    },
    skip: skip || !primaryRecordId,
    fetchPolicy: 'no-cache',
  });

  return {
    primaryRecordType: data?.primaryRecord.type,
    isPrimaryRecordTypeLoading: loading,
    primaryRecordTypeError: error,
  };
};
