import { RequirementComplianceStatus } from '@trustlayer/common';

export const REQUEST_RECORDS_ACTION_TYPES = {
  archive: 'archive',
  makeActive: 'makeActive',
  delete: 'delete',
} as const;

export type ActionType =
  (typeof REQUEST_RECORDS_ACTION_TYPES)[keyof typeof REQUEST_RECORDS_ACTION_TYPES];

export const REQUEST_RECORDS_TABS = {
  Compliance: {
    name: 'Compliance',
    key: 'compliance',
  },
  Checklist: {
    name: 'Checklist',
    key: 'checklist',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
} as const;

export const STATUS_TYPES = {
  default: 'default',
  waived: RequirementComplianceStatus.Waived,
  overridden: RequirementComplianceStatus.Overridden,
} as const;

export type STATUS_TYPES = (typeof STATUS_TYPES)[keyof typeof STATUS_TYPES];

export enum ACTION_TYPES {
  editWaiveOverride = 'editWaiveOverride',
  removeWaiveOverride = 'removeWaiveOverride',
  waive = 'waive',
  override = 'override',
  addPublicNote = 'addPublicNote',
  editPublicNote = 'editPublicNote',
  deletePublicNote = 'deletePublicNote',
}

export const WAIVER_ACTIONS = [
  {
    label: 'Edit waiver',
    code: ACTION_TYPES.editWaiveOverride,
  },
  {
    label: 'Remove waiver',
    code: ACTION_TYPES.removeWaiveOverride,
  },
];

export const OVERRIDE_ACTIONS = [
  {
    label: 'Edit override',
    code: ACTION_TYPES.editWaiveOverride,
  },
  {
    label: 'Remove override',
    code: ACTION_TYPES.removeWaiveOverride,
  },
];

export const REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES = {
  deleteRequestRecords: 'mutation deleteRequestRecords',
  createRequestRecords: 'mutation createRequestRecords',
  updateRecordAttributes: 'mutation updateRecordAttributes',
  archiveRequestRecords: 'mutation archiveRequestRecords',
  activateRequestRecords: 'mutation activateRequestRecords',
} as const;

export type REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES =
  (typeof REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES)[keyof typeof REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES];
