import { SaveOutlined } from '@ant-design/icons';
import { LoadingSpinner } from '@common/components/Spinner/LoadingSpinner';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { ChecklistProfileAlert } from '@modules/document-checklist/components/ChecklistProfileAlert';
import type { DocumentChecklist } from '@modules/document-checklist/types';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import {
  DocumentCheckListForm,
  type DocumentChecklistFormData,
} from '../../components/DocumentCheckListForm';

import {
  DocumentCheckListFormDrawer as DocumentCheckListFormDrawerComponent,
  getFormInitialValues,
  getFormValuesAsUpdatePayload,
} from '@modules/document-checklist/components/DocumentCheckListFormDrawer';
import { useCreateDocumentChecklistMutation } from '@modules/document-checklist/hooks/useCreateDocumentChecklistMutation';
import { useDocumentChecklistsQuery } from '@modules/document-checklist/hooks/useDocumentChecklistsQuery';
import { useDocumentTypesQuery } from '@modules/document-checklist/hooks/useDocumentTypesQuery';
import { useUpdateDocumentChecklistMutation } from '@modules/document-checklist/hooks/useUpdateDocumentChecklistMutation';

type DocumentCheckListFormDrawerProps = {
  checklistId?: string;
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const DocumentCheckListFormDrawerInner = ({
  checklistId,
  onClose,
  onSuccess,
  visible,
}: DocumentCheckListFormDrawerProps) => {
  const isEdit = !!checklistId;

  const { complianceProfile, isComplianceProfileLoading } =
    useDocumentChecklistsQuery({
      checklistId,
      onCompleted: (data) => {
        form.setFieldsValue(
          getFormInitialValues(
            data.documentChecklists as DocumentChecklist[],
            data.name || '',
          ),
        );
      },
    });

  const { documentTypes, isDocumentTypesLoading } = useDocumentTypesQuery({
    skip: !visible,
  });

  const [form] = Form.useForm<DocumentChecklistFormData>();

  const { createDocumentChecklist, isCreateDocumentChecklistLoading } =
    useCreateDocumentChecklistMutation();
  const { updateDocumentChecklist, isUpdateDocumentChecklistLoading } =
    useUpdateDocumentChecklistMutation();

  const isSubmitting =
    isCreateDocumentChecklistLoading || isUpdateDocumentChecklistLoading;

  const handleSubmit = async (values: DocumentChecklistFormData) => {
    const isValid = await form.validateFields();

    if (!isValid) return;

    const payload = getFormValuesAsUpdatePayload(
      values,
      complianceProfile?._id,
    );

    const fn = isEdit ? updateDocumentChecklist : createDocumentChecklist;
    await fn({ variables: { payload } });
    onSuccess();
  };

  const isEditingLoading = checklistId && isComplianceProfileLoading;
  const isLoading = isEditingLoading || isDocumentTypesLoading;

  return (
    <DocumentCheckListFormDrawerComponent
      form={form}
      onClose={onClose}
      onSubmit={handleSubmit}
      onValuesChange={() => {}}
      visible={visible}
    >
      <StyledHeader>
        <StyledNameContainer>
          <div className="label">Name</div>
          <div className="field">
            <Form.Item
              name="name"
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input
                placeholder="Checklist name"
                size="large"
                width={'100%'}
                data-cy="checklistNameInput"
              />
            </Form.Item>
          </div>
        </StyledNameContainer>

        <Button
          data-cy="saveProfileButton"
          icon={<SaveOutlined />}
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
        >
          Save Changes
        </Button>
      </StyledHeader>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {complianceProfile?._id && (
            <ChecklistProfileAlert
              profileName={complianceProfile?.name || ''}
            />
          )}
          <DocumentCheckListForm documentTypes={documentTypes} />
        </>
      )}
    </DocumentCheckListFormDrawerComponent>
  );
};

const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;

  .field {
    flex: 1;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryPaleBlue};
  margin-bottom: 20px;
  gap: 20px;

  h3 {
    margin: 0;
  }
`;

export const DocumentCheckListFormDrawer = withControlledMount(
  DocumentCheckListFormDrawerInner,
  {
    mountWhen: ({ visible }) => visible,
  },
);
