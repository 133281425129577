import { TagSkeleton } from '@common/components/TagSkeleton';
import { FILTER_OPERATORS } from '@common/constants/filters';
import {
  BulkOperationStatus,
  type PrimaryRecordTag,
} from '@graphql/types/graphql';
import { Tag } from '@modules/party';
import AutoCompletePartyTag from '@modules/party-tag/containers/AutoCompletePartyTag';
import { Skeleton, message } from 'antd';
import { useState } from 'react';
import { styled } from 'styled-components';
import { useAddTagsToPrimaryRecords } from '../../hooks/useAddTagsToPrimaryRecords';
import { useRemoveTagsFromPrimaryRecords } from '../../hooks/useRemoveTagsFromPrimaryRecords';
import { ExpirationDatePopover } from '../ExpirationDatePopover';
import { usePrimaryRecordSubheaderQuery } from './hooks/usePrimaryRecordSubheaderQuery';

type PrimaryRecordSubheaderProp = {
  primaryRecordId: string;
};

const TAG_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  UPDATE: 'update',
} as const;

export const PrimaryRecordSubheader = ({
  primaryRecordId,
}: PrimaryRecordSubheaderProp) => {
  const [currentTagAction, setCurrentTagAction] = useState<
    (typeof TAG_ACTIONS)[keyof typeof TAG_ACTIONS] | undefined
  >();

  const [removingTagId, setRemovingTagId] = useState<string | undefined>();

  const { tags, loading, refetch, isRefetching } =
    usePrimaryRecordSubheaderQuery({
      primaryRecordId,
      skip: !primaryRecordId,
    });

  const { removeTagsFromPrimaryRecords, loading: removeTagsLoading } =
    useRemoveTagsFromPrimaryRecords();

  const { addTagsToPrimaryRecords, loading: addTagsLoading } =
    useAddTagsToPrimaryRecords();

  const handleSelect = async (tag: { _id?: string; name: string }) => {
    setCurrentTagAction(TAG_ACTIONS.ADD);
    const res = await addTagsToPrimaryRecords([tag], {
      and: [
        {
          name: 'id',
          operator: FILTER_OPERATORS.equal,
          value: primaryRecordId,
        },
      ],
    });

    const status = res.data?.addTagsToPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      await refetch();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        'The tags assignment failed. Please try again or contact support.',
      );
    }

    setCurrentTagAction(undefined);
  };

  const handleRemove = async (tag: PrimaryRecordTag) => {
    setRemovingTagId(tag._id);
    setCurrentTagAction(TAG_ACTIONS.REMOVE);
    const res = await removeTagsFromPrimaryRecords({
      tagIds: [tag._id],
      filters: {
        and: [
          {
            name: 'id',
            operator: FILTER_OPERATORS.equal,
            value: primaryRecordId,
          },
        ],
      },
    });

    const status = res.data?.removeTagsFromPrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      await refetch();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        'The tags removal failed. Please try again or contact support.',
      );
    }

    setCurrentTagAction(undefined);
    setRemovingTagId(undefined);
  };

  return (
    <StyledTags>
      {loading && !isRefetching ? (
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      ) : (
        <>
          {tags.map((tag) => {
            if (
              currentTagAction === TAG_ACTIONS.REMOVE &&
              removingTagId === tag._id
            ) {
              return <TagSkeleton />;
            }

            return (
              <ExpirationDatePopover
                showLoadingOnUpdate
                key={`key_${tag._id}`}
                tag={tag}
                primaryRecordId={primaryRecordId}
                onDateAdded={() => {
                  refetch();
                }}
              >
                <Tag
                  label={tag.name}
                  expiresAt={tag.expiresAt}
                  onClose={() => {
                    handleRemove(tag);
                  }}
                />
              </ExpirationDatePopover>
            );
          })}
          {currentTagAction === TAG_ACTIONS.ADD ? (
            <TagSkeleton />
          ) : (
            <AutoCompletePartyTag
              disabled={addTagsLoading || isRefetching || removeTagsLoading}
              onTagSelected={handleSelect}
              onNewTagSelected={handleSelect}
            />
          )}
        </>
      )}
    </StyledTags>
  );
};

const StyledTags = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
