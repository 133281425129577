import { CollapseCard } from '@common/components/CollapseCard';
import Flex from '@common/components/Flex';
import { RequestRecordDocumentCheckListFormDrawer } from '@modules/document-checklist/containers/RequestRecordDocumentCheckListFormDrawer';
import {
  ComplianceSummary,
  ActionKeys as ComplianceSummaryActionKeys,
} from '@modules/request-records';
import { RequestRecordDocumentsChecklist } from '@modules/request-records/components/RequestRecordDocumentsChecklist';
import { AssignComplianceProfileModal } from '@modules/request-records/containers/AssignComplianceProfileModal';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { FullWidthSpace } from '@trustlayer/ui';
import { Card, Result, Typography } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useRequestRecordComplianceQuery } from './hooks/useRequestRecordComplianceQuery';

export function RequestRecordChecklist() {
  const [
    isAssignComplianceProfileModalVisible,
    setIsAssignComplianceProfileModalVisible,
  ] = useState(false);
  const [isDocumentChecklistModalVisible, setIsDocumentChecklistModalVisible] =
    useState(false);

  const { requestId } = useParams<RequestRecordsRoutesParams>();
  const {
    requestRecord,
    loadingRequestRecordCompliance,
    errorRequestRecordCompliance,
    refetchRequestRecordCompliance,
  } = useRequestRecordComplianceQuery({ requestRecordId: requestId! });
  const complianceProfile = requestRecord?.complianceProfile;
  const documentsChecklist = requestRecord?.documentsChecklist || [];

  if (errorRequestRecordCompliance) {
    return (
      <StyledErrorWrapper direction="vertical" justify="center">
        <Result
          status="500"
          title="500"
          subTitle="Oops! Something went wrong. Please try again later."
        />
      </StyledErrorWrapper>
    );
  }

  const handleComplianceSummaryActionClick = (
    actionKey: ComplianceSummaryActionKeys,
  ) => {
    if (
      actionKey === ComplianceSummaryActionKeys.edit ||
      actionKey === ComplianceSummaryActionKeys.assign
    ) {
      if (requestRecord?.complianceProfile) {
        setIsDocumentChecklistModalVisible(true);
      } else {
        setIsAssignComplianceProfileModalVisible(true);
      }
    }
  };
  return (
    <>
      {requestId && (
        <>
          <RequestRecordDocumentCheckListFormDrawer
            visible={isDocumentChecklistModalVisible}
            checklist={{
              _id: complianceProfile?._id,
              name: complianceProfile?.name!,
              baseComplianceProfile: complianceProfile?.baseComplianceProfile,
            }}
            requestRecord={{
              _id: requestId,
              name: requestRecord?.name,
            }}
            onSuccess={() => {
              setIsDocumentChecklistModalVisible(false);
              refetchRequestRecordCompliance();
            }}
            onClose={() => setIsDocumentChecklistModalVisible(false)}
          />
          <AssignComplianceProfileModal
            isShow={isAssignComplianceProfileModalVisible}
            requestRecordId={requestId}
            complianceProfile={complianceProfile!}
            onSuccess={() => {
              setIsAssignComplianceProfileModalVisible(false);
              refetchRequestRecordCompliance();
            }}
            onClose={() => setIsAssignComplianceProfileModalVisible(false)}
          />
        </>
      )}
      <FullWidthSpace size="small" direction="vertical">
        <ComplianceSummary
          hasProfile={Boolean(complianceProfile)}
          isCustomComplianceProfile={Boolean(
            complianceProfile?.baseComplianceProfile,
          )}
          name={complianceProfile?.name}
          onActionClick={handleComplianceSummaryActionClick}
          loading={loadingRequestRecordCompliance}
        />

        {loadingRequestRecordCompliance && (
          <>
            <Card loading />
            <Card loading />
            <Card loading />
          </>
        )}

        {!loadingRequestRecordCompliance &&
          Boolean(documentsChecklist.length) && (
            <CollapseCard key={'documents-checklist'}>
              <CollapseCard.Header>
                <StyledTitle level={5}>Documents checklist</StyledTitle>
              </CollapseCard.Header>
              <CollapseCard.Body>
                <RequestRecordDocumentsChecklist
                  documentsChecklist={documentsChecklist}
                />
              </CollapseCard.Body>
            </CollapseCard>
          )}
      </FullWidthSpace>
    </>
  );
}

const StyledErrorWrapper = styled(Flex)`
  height: 100%;
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;
