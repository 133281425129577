import type {
  ComplianceRequirement,
  ComplianceSubject,
} from '@graphql/types/graphql';
import { useState } from 'react';
import type { ActionType, ItemType } from '../constants';

type SubjectData = {
  code: ComplianceSubject['code'];
  label: string;
  status: ComplianceSubject['status'];
  notes?: ComplianceSubject['notes'];
  resetOn?: ComplianceSubject['resetOn'];
  publicNote?: ComplianceRequirement['publicNotes'];
};

type RequirementData = {
  code: ComplianceRequirement['attributeCode'];
  label: string;
  publicNote?: ComplianceRequirement['publicNotes'];
  status: ComplianceRequirement['status'];
  notes?: ComplianceRequirement['notes'];
  resetOn?: ComplianceRequirement['resetOn'];
};

type RemoveWaiverOverrideModalState =
  | {
      visible: false;
      actionType?: ActionType;
      itemType?: ItemType;
      itemData?: {
        code: SubjectData['code'] | RequirementData['code'];
      };
    }
  | {
      visible: true;
      actionType: ActionType;
      itemType: ItemType;
      itemData: {
        code: SubjectData['code'] | RequirementData['code'];
      };
    };

export const useRemoveWaiveOverrideModalState = () => {
  const [removeWaiverOverrideModalState, setRemoveWaiverOverrideModalState] =
    useState<RemoveWaiverOverrideModalState>({
      visible: false,
    });

  return {
    removeWaiverOverrideModalState,
    setRemoveWaiverOverrideModalState,
  };
};
